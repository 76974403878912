import MuiPagination from '@compodium/ui-lib/material/Pagination';

const noop = () => {};

export default function Pagination({
  page = 0,
  setPage = noop,
  total = 0,
  pageSize = 10,
  sx = {},
}) {
  return (
    <MuiPagination
      count={Math.ceil(total / pageSize)}
      page={page + 1}
      onChange={(_, p) => setPage(p - 1)}
      showFirstButton
      showLastButton
      siblingCount={1}
      boundaryCount={1}
      color="primary"
      sx={sx}
    />
  );
}
