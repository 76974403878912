import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  TableCell,
} from '@compodium/ui-lib/material';
import DeleteIcon from '@compodium/ui-lib/icons/Delete';
import { dur, toHuman, toHumanTime, toHumanDate } from '../../utils/time';

export default function useMeetingList({ onDelete, onMobileClick }) {
  const { t } = useTranslation();

  const headers = [t('name'), t('date'), t('time'), t('duration'), ''];

  const renderMobileItem = (meeting) => {
    const { id, createdAt } = meeting;
    return (
      <ListItem disableGutters>
        <ListItemButton onClick={() => onMobileClick(meeting)}>
          <ListItemText primary={id} secondary={toHuman(createdAt)} />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderRow = (meeting) => {
    const { id, createdAt, stoppedAt } = meeting;

    const stopProp = (e) => e.stopPropagation();

    return (
      <>
        <TableCell>{id}</TableCell>
        <TableCell>{toHumanDate(createdAt)}</TableCell>
        <TableCell>{toHumanTime(createdAt)}</TableCell>
        <TableCell>{dur(createdAt, stoppedAt)}</TableCell>
        <TableCell onClick={stopProp}>
          <IconButton onClick={() => onDelete(meeting)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return {
    headers,
    renderRow,
    renderMobileItem,
  };
}
