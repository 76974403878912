import { get, post, del, cancelCalls, addCtCheck } from '../utils/api';
import intStore from '../stores/IntegrationStore';
import userStore from '../stores/UserStore';

export async function getIntegrations() {
  const o = userStore.org;
  if (!o) return Promise.reject();
  cancelCalls('getIntegrations');
  const cancelToken = addCtCheck('getIntegrations');
  return get(`/orgs/${o}/integrations`, {}, cancelToken).then((res) => {
    if (!res?.data) return;
    const { integrations, prefix } = res.data;
    intStore.integrations = integrations || [];
    intStore.prefix = prefix || '';
  });
}

export async function createIntegration() {
  const o = userStore.org;
  return post(`/orgs/${o}/integrations`).then((res) => {
    const { integrations, prefix } = res.data;
    intStore.integrations = integrations || [];
    intStore.prefix = prefix || '';
  });
}

export async function createTokenIntegration() {
  const o = userStore.org;
  return post(`/orgs/${o}/tokenIntegrations`).then((res) => {
    const { clientId, secret } = res.data;
    console.warn(
      `bin/new-video-url.js --clientId ${clientId} --clientSecret ${secret}  --roomId asdf:23 --userId 02 --userName "Alice2"`
    );
  });
}

export async function deleteIntegration(apiKey) {
  const o = userStore.org;
  return del(`/orgs/${o}/integrations/${apiKey}`);
}
