import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField } from '@compodium/ui-lib/material';
import Dialog from '../Modals/Dialog';
import RoleSelect from '../RoleSelect';

export default function InviteUserDialog({ open, onClose, onSubmit }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('user');

  const handle = (key) => (e) => {
    switch (key) {
      case 'email':
        setEmail(e.target.value);
        break;
      case 'role':
        setRole(e.target.value);
        break;
      default:
        break;
    }
  };

  const doClose = () => {
    onClose();
    setEmail('');
  };

  const doSubmit = () => {
    onSubmit(email, role);
    doClose();
  };

  const submitOnEnter = (e) => {
    if (e.key === 'Enter') {
      doSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={doClose}
      onSubmit={doSubmit}
      title={t('invite_user')}
      submitText={t('invite')}
      maxWidth="xs"
    >
      <Stack gap={1} mt={0.5}>
        <TextField
          label={t('email')}
          value={email}
          onKeyPress={submitOnEnter}
          onChange={handle('email')}
          size="small"
        />
        <RoleSelect
          value={role}
          onChange={handle('role')}
          renderValue={(v) => t(v)}
        />
      </Stack>
    </Dialog>
  );
}
