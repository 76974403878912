import { useTranslation } from 'react-i18next';
import {
  IconButton,
  InputAdornment,
  TextField,
} from '@compodium/ui-lib/material';
import { Clear, Search } from '@compodium/ui-lib/icons';

export default function SearchBar({
  value,
  onChange,
  placeholder,
  isClearable = false,
  sx = {},
  inputSx = {},
}) {
  const { t } = useTranslation();

  const clear = () => onChange({ target: { value: '' } });

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {isClearable && value ? (
              <IconButton aria-label="clear search" onClick={clear} edge="end">
                <Clear />
              </IconButton>
            ) : null}
          </InputAdornment>
        ),
        sx: { inputSx },
      }}
      placeholder={placeholder ?? t('search')}
      value={value}
      onChange={onChange}
      variant="outlined"
      size="small"
      sx={sx}
    />
  );
}
