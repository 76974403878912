import { Box } from '@compodium/ui-lib/material';

export default function CamIcon({ size = 48 }) {
  const src = `${process.env.PUBLIC_URL}/svgs/meet-primary.svg`;

  return (
    <Box
      component="img"
      src={src}
      alt="cam"
      loading="lazy"
      width={size}
      height={size}
    />
  );
}
