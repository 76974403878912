import { useTranslation } from 'react-i18next';
import { Delete } from '@compodium/ui-lib/icons';
import Dialog from './Dialog';

export default function ConfirmDeleteDialog({
  title,
  children,
  open,
  onConfirm,
  onCancel,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onSubmit={onConfirm}
      title={title || t('confirm_delete_title')}
      submitText={t('confirm')}
      submitIcon={<Delete />}
      submitColor="error"
    >
      {children}
    </Dialog>
  );
}
