import { useTranslation } from 'react-i18next';
import {
  Divider,
  Stack,
  Text,
  LinearProgress,
} from '@compodium/ui-lib/material';

const msToMinutes = (ms) => Math.round(ms / 1000 / 60);

export default function SubUsage({ sub, renewalDate, infinityConstant }) {
  const { t } = useTranslation();
  const {
    memberCount,
    limits: {
      maxMeetingDurationMs,
      meetingDurationUsed,
      maxCallRecordingDurationMs,
      callRecordingDurationUsed,
    } = {},
  } = sub || {};
  const showMemberCount = sub?.type === 'meet';
  const showMeetingUsage = true;
  const showCallRecordingUsage = sub?.attributes?.callRecording;

  const meetingUsedPercentage = Math.round(
    (meetingDurationUsed / maxMeetingDurationMs) * 100
  );
  const callRecordingUsedPercentage = Math.round(
    (callRecordingDurationUsed / maxCallRecordingDurationMs) * 100
  );
  const meetingUsed = msToMinutes(meetingDurationUsed);
  const meetingTotal = msToMinutes(maxMeetingDurationMs);
  const callRecordingUsed = msToMinutes(callRecordingDurationUsed);
  const callRecordingTotal = msToMinutes(maxCallRecordingDurationMs);

  const meetingDurIsLimited = maxMeetingDurationMs !== infinityConstant;
  const callRecIsLimited = maxCallRecordingDurationMs !== infinityConstant;

  return (
    <Stack gap={0.75}>
      {showMemberCount && (
        <>
          <Stack>
            <Text>{t('member_count')}</Text>
            <Text color="text.secondary">{memberCount}</Text>
          </Stack>
          <Divider />
        </>
      )}
      {showMeetingUsage && (
        <>
          <Stack>
            <Text>{t('meeting_duration_used')}</Text>
            {meetingDurIsLimited && (
              <LinearProgress
                variant="determinate"
                value={meetingUsedPercentage}
                sx={{ maxWidth: 200 }}
              />
            )}
            <Text color="text.secondary">
              {meetingDurIsLimited
                ? t('minutes_left {{part}} {{total}} {{renewalDate}}', {
                    part: meetingTotal - meetingUsed,
                    total: meetingTotal,
                    renewalDate,
                  })
                : t('minutes_used {{used}} {{renewalDate}}', {
                    used: msToMinutes(meetingDurationUsed),
                    renewalDate,
                  })}
            </Text>
          </Stack>
          <Divider />
        </>
      )}
      {showCallRecordingUsage && (
        <Stack>
          <Text>{t('call_recording_duration_used')}</Text>
          {callRecIsLimited && (
            <LinearProgress
              variant="determinate"
              value={callRecordingUsedPercentage}
              sx={{ maxWidth: 200 }}
            />
          )}
          <Text color="text.secondary">
            {callRecIsLimited
              ? t('minutes_left {{part}} {{total}} {{renewalDate}}', {
                  part: callRecordingTotal - callRecordingUsed,
                  total: callRecordingTotal,
                  renewalDate,
                })
              : t('minutes_used {{used}} {{renewalDate}}', {
                  used: msToMinutes(callRecordingDurationUsed),
                  renewalDate,
                })}
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
