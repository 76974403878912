import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  Avatar,
  Button,
  Breadcrumbs,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import BellIcon from '../components/icons/Bell';
import SearchBar from '../components/SearchBar';
import Wrapper from '../components/layout/Wrapper';
import useIsMobile from '../hooks/useIsMobile';
import useUser from '../hooks/useUser';
import {
  getOrgs,
  getNotifications,
  acceptInvite,
  denyInvite,
} from '../actions/UserActions';

function Title() {
  const { t } = useTranslation();
  return (
    <Stack gap={1} direction="row" justifyContent="space-between">
      <Stack gap={0.5}>
        <Breadcrumbs>
          <Text component={Link} to="/" color="primary">
            {t('home_title')}
          </Text>
          <Text color="primary">{t('notifications_title')}</Text>
        </Breadcrumbs>
        <Text variant="h4">{t('notifications_title')}</Text>
        <Text variant="body1">{t('notifications_desc')}</Text>
      </Stack>
    </Stack>
  );
}

function Empty() {
  const { t } = useTranslation();
  const { darkmode } = useUser();

  return (
    <Stack
      gap={0.5}
      sx={{
        borderRadius: '8px',
        p: 2,
        backgroundColor: darkmode ? 'grey.900' : 'grey.100',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <BellIcon gray darkmode={darkmode} />
      <Text variant="h5">{t('notifications_empty_title')}</Text>
      <Text variant="body1">{t('notifications_empty_desc')}</Text>
    </Stack>
  );
}

function NotificationList() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [rawQ, setQ] = useState('');
  const [q] = useDebounce(rawQ, 500);
  const { notifications } = useUser();
  const numNewNotifications = notifications.filter(
    (notification) => !notification.read
  ).length;

  useEffect(() => {
    getNotifications();
  }, []);

  const onAccept = (notification) => {
    acceptInvite(notification.name).then(getOrgs).then(getNotifications);
  };

  const onDecline = (notification) => {
    denyInvite(notification.name).then(getNotifications);
  };

  return (
    <Stack gap={2}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isMobile ? 'start' : 'center'}
      >
        <Text variant="h6">{t('news')}</Text>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={0.5}
          alignItems={isMobile ? 'start' : 'center'}
          width={isMobile ? '100%' : undefined}
        >
          <Text
            variant="subtitle1"
            color="text.secondary"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {t('notifications_new {{count}}', { count: numNewNotifications })}
          </Text>
          <SearchBar q={q} setQ={setQ} sx={{ width: '100%' }} />
        </Stack>
      </Stack>

      {notifications?.length < 1 ? (
        <Empty />
      ) : (
        notifications.map((notification) => (
          <Stack key={notification.id}>
            {notification.type === 'invite' ? (
              <Stack gap={1} direction="row" alignItems="center">
                <Avatar alt={notification.displayName} />
                <Text>
                  {t('invited_to {{name}}', { name: notification.displayName })}
                </Text>
                <Stack direction="row" gap={0.5}>
                  <Button
                    variant="contained"
                    onClick={() => onAccept(notification)}
                  >
                    {t('accept')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => onDecline(notification)}
                  >
                    {t('decline')}
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Text>{JSON.stringify(notification)}</Text>
            )}
          </Stack>
        ))
      )}
    </Stack>
  );
}

export default function Notifications() {
  return (
    <Wrapper>
      <Stack gap={2}>
        <Title />
        <NotificationList />
      </Stack>
    </Wrapper>
  );
}
