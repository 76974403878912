import { useTranslation } from 'react-i18next';
import { Stack, Text, Button } from '@compodium/ui-lib/material';

export default function SetupComplete({ onDone }) {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Text variant="h4">{t('setup_workspace_complete')}</Text>
      <Text variant="body1">{t('setup_workspace_complete_desc')}</Text>
      <Button
        variant="contained"
        onClick={onDone}
        autoFocus
        sx={{ height: 48, width: 150 }}
      >
        {t('browse_workspace')}
      </Button>
    </Stack>
  );
}
