import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@compodium/ui-lib/material';
import useUser from '../hooks/useUser';

/* eslint-disable no-param-reassign */
function byString(o, path) {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  const a = path.split('.');
  for (let i = 0, n = a.length; i < n; i += 1) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return null;
    }
  }
  return o;
}
/* eslint-enable no-param-reassign */

export default function Restricted({
  RestrictedComponent,
  isAdmin = false,
  permission = '',
  redirectTo = '/',
}) {
  const { permissions, loading } = useUser();

  const ok =
    (isAdmin && permissions.isAdmin) ||
    (permission && byString(permissions, permission));

  if (loading) return <CircularProgress />;
  if (!ok) return <Navigate to={redirectTo} />;

  return <RestrictedComponent />;
}
