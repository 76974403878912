import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import { styled } from '@compodium/ui-lib/material/styles';
import {
  Analytics as AnalyticsIcon,
  Home as HomeIcon,
  Notifications as BellIcon,
  Videocam as VideocamIcon,
  Payments as SubscriptionsIcon,
  Settings as SettingsIcon,
  Help as QuestionIcon,
  SyncAlt as ConfigureIcon,
  BarChart as InsightsIcon,
  Description as ApiDocsIcon,
  Code as DevGuideIcon,
} from '@compodium/ui-lib/icons';
import Logo from '../Logo';
import OrgSelect from '../OrgSelect';
import ThemeProvider from '../ThemeProvider';
import UserCard from '../UserCard';
import useUser from '../../hooks/useUser';

const styleFn = ({ theme }) => ({
  color: theme.palette.blackish.contrastText,
  textDecoration: 'none',
  '&:hover': {
    background: theme.palette.action.hover,
  },
  '&.active': {
    fontWeight: 600,
  },
});
const Nav = styled(NavLink)(styleFn);

function NotificationIcon() {
  const { notifications } = useUser();
  const badgeContent = notifications?.length || 0;
  return (
    <Badge
      color="primary"
      badgeContent={badgeContent}
      invisible={badgeContent === 0}
    >
      <BellIcon />
    </Badge>
  );
}

const iconSx = {
  color: 'text.primary',
  opacity: 0.56,
};

function EmbeddedSection() {
  const { t } = useTranslation();
  const links = [
    {
      key: 1,
      icon: <ConfigureIcon />,
      link: '/embedded/configure',
      title: t('configure_title'),
    },
    {
      key: 2,
      icon: <InsightsIcon />,
      link: '/embedded/insights',
      title: t('insights_title'),
    },
    {
      key: 3,
      icon: <ApiDocsIcon />,
      link: 'https://docs.compodium.com/api',
      title: t('api_docs_title'),
      external: true,
    },
    {
      key: 4,
      icon: <DevGuideIcon />,
      link: 'https://docs.compodium.com/docs/Get%20started',
      title: t('dev_guide_title'),
      external: true,
    },
  ];

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Stack id="embedded">
      <ListItem disableGutters>
        <ListItemText>
          <Text variant="subtitle1">{t('embedded_title')}</Text>
        </ListItemText>
      </ListItem>
      {links.map(({ key, icon, link, title, external }) => (
        <ListItem
          key={key}
          disableGutters
          component={Nav}
          to={link}
          {...(external ? { target: '_blank', rel: 'noreferrer' } : {})}
        >
          <ListItemIcon sx={iconSx}>{icon}</ListItemIcon>
          {title}
        </ListItem>
      ))}
    </Stack>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export default function Navigation() {
  const { t } = useTranslation();
  const { darkmode, permissions, orgs } = useUser();
  const hasOrgs = orgs?.length > 0;
  const links = [
    { key: 1, icon: <HomeIcon />, link: '/', title: t('home_title') },
    {
      key: 2,
      icon: <NotificationIcon />,
      link: '/notifications',
      title: t('notifications_title'),
    },
    { key: 3, divider: true },
    // { link: '/dashboard', title: t('dashboard_title') },
    ...(hasOrgs
      ? [
          {
            key: 4,
            icon: <VideocamIcon />,
            link: '/meetings',
            title: t('meetings_title'),
          },
          {
            key: 5,
            icon: <SubscriptionsIcon />,
            link: '/subscriptions',
            title: t('subscriptions_title'),
          },
          {
            key: 6,
            icon: <AnalyticsIcon />,
            link: '/analytics',
            title: t('analytics_title'),
          },
        ]
      : []),
    // { link: '/billing', title: t('billing_title') },
  ].filter((x) => x);
  const footerLinks = [
    {
      key: 1,
      icon: <SettingsIcon />,
      link: '/settings',
      title: t('settings_title'),
    },
    {
      key: 2,
      icon: <QuestionIcon />,
      link: 'https://docs.compodium.com',
      external: true,
      title: t('help_title'),
    },
  ];

  /* eslint-disable react/jsx-props-no-spreading */

  return (
    <ThemeProvider darkmode noBaseline>
      <Paper
        component="aside"
        sx={{
          display: 'flex',
          py: 1.5,
          px: 1,
          width: '100%',
          height: '100%',
          borderRadius: 0,
          backgroundColor: darkmode ? 'background.default' : 'blackish.main',
          overflowY: 'auto',
        }}
      >
        <Stack gap={1} justifyContent="space-between" flexGrow={2}>
          <Stack gap={1}>
            <Logo darkmode sx={{ alignSelf: 'start' }} />
            <Box>
              <OrgSelect fullWidth variant="standard" showAddOption />
            </Box>
            <List component="nav">
              {links.map(({ key, divider, icon, link, title }) =>
                divider ? (
                  <Divider key={key} sx={{ my: 1 }} />
                ) : (
                  <ListItem key={link} disableGutters component={Nav} to={link}>
                    <ListItemIcon sx={iconSx}>{icon}</ListItemIcon>
                    {title}
                  </ListItem>
                )
              )}
            </List>
          </Stack>
          {permissions?.read?.integrations ? <EmbeddedSection /> : ''}
          <Stack component="footer" gap={0.5}>
            <List>
              {footerLinks.map(({ key, icon, link, title, external }) => (
                <ListItem
                  key={key}
                  disableGutters
                  component={Nav}
                  to={link}
                  {...(external ? { target: '_blank', rel: 'noreferrer' } : {})}
                >
                  <ListItemIcon sx={iconSx}>{icon}</ListItemIcon>
                  {title}
                </ListItem>
              ))}
            </List>
            <Divider flexItem sx={{ my: 1 }} />
            <UserCard />
          </Stack>
        </Stack>
      </Paper>
    </ThemeProvider>
  );
}
