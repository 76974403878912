import { Component } from 'react';
import { Stack, Text } from '@compodium/ui-lib/material';
import logger from '../../utils/logger';

function CatchBlock({ error }) {
  return (
    <Stack gap={1}>
      <Text variant="h1">Something went wrong</Text>
      <Text variant="h3">{error?.name}</Text>
      <Text>{error?.message}</Text>
      {error?.stack ? <Stack>{error.stack}</Stack> : null}
    </Stack>
  );
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    return error ? <CatchBlock error={error} /> : children;
  }
}
