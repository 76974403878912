import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, IconButton, Menu } from '@compodium/ui-lib/material';
import MoreIcon from '@compodium/ui-lib/icons/MoreVert';
import DeleteWorkspaceMenuItem from './DeleteWorkspaceMenuItem';
import EditWorkspaceMenuItem from './EditWorkspaceMenuItem';
import useIsMobile from '../../hooks/useIsMobile';

export default function WorkspaceOptionsMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const onOpen = (e) => setAnchorEl(e.currentTarget);
  const onClose = () => setAnchorEl(null);

  return (
    <>
      {isMobile ? (
        <IconButton onClick={onOpen}>
          <MoreIcon />
        </IconButton>
      ) : (
        <Button variant="outlined" startIcon={<MoreIcon />} onClick={onOpen}>
          {t('options')}
        </Button>
      )}
      <Menu open={Boolean(anchorEl)} onClose={onClose} anchorEl={anchorEl}>
        <EditWorkspaceMenuItem />
        <Divider />
        <DeleteWorkspaceMenuItem />
      </Menu>
    </>
  );
}
