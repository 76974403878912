import { t } from 'i18next';
import {
  get,
  post,
  put,
  del,
  cancelCalls,
  addCtCheck,
  objectToQuery,
} from '../utils/api';
import { addSnack } from './SnackActions';
import userStore from '../stores/UserStore';
import workspaceStore from '../stores/WorkspaceStore';
import logger from '../log';

export async function sendRequest(orgName) {
  return post(`/orgs/${orgName}/requests`).then(() => {
    addSnack(t('request_sent'));
  });
}

export async function getRequests({ q, offset, limit }) {
  const o = userStore.org;
  cancelCalls('getRequests');
  const cancelToken = addCtCheck('getRequests');
  const query = objectToQuery({ q, offset, limit });
  return get(`/orgs/${o}/requests?${query}`, {}, cancelToken).then((res) => {
    if (!res?.data) return;
    const { requests, total } = res.data;
    workspaceStore.requests = requests || [];
    workspaceStore.totalRequests = total || 0;
  });
}

export async function getMembers({ q, offset, limit }) {
  const o = userStore.org;
  cancelCalls('getMembers');
  const cancelToken = addCtCheck('getMembers');
  const query = objectToQuery({ q, offset, limit });
  return get(
    `/orgs/${o}/members${query?.length ? `?${query}` : ''}`,
    {},
    cancelToken
  ).then((res) => {
    if (!res?.data) return;
    const { members, total } = res.data;
    workspaceStore.members = members || [];
    workspaceStore.totalMembers = total || 0;
  });
}

export async function replyToRequest(id, accept) {
  const o = userStore.org;
  return post(`/orgs/${o}/requests/${id}`, { accept }).then(() => {
    addSnack(t('request_replied'));
  });
}

export async function inviteUser(email, role) {
  const o = userStore.org;
  return post(`/orgs/${o}/invite`, { email, role }).then(() => {
    addSnack(t('user_invited'));
  });
}

export async function orgNameAvailable(name) {
  /* data: {org, available} */
  return get(`/orgs/${name}/available`).then((res) => res.data);
}

export async function createOrg(name) {
  return post(`/orgs`, { name }).then((res) => res?.data?.group || {});
}

export async function setUserRole(id, role) {
  const o = userStore.org;
  return post(`/orgs/${o}/users/${id}/role`, { role })
    .then(() => {
      addSnack(t('user_updated'));
    })
    .catch((e) => {
      logger.error(e);
      addSnack(t('user_update_failed'));
    });
}

export async function removeUser(id) {
  const o = userStore.org;
  return del(`/orgs/${o}/users/${id}`)
    .then(() => {
      addSnack(t('user_removed'));
    })
    .catch((e) => {
      logger.error(e);
      addSnack(t('user_remove_failed'));
    });
}

export async function deleteWorkspace() {
  const o = userStore.org;
  return del(`/orgs/${o}`).then(() => {
    addSnack(t('workspace_deleted'));
  });
}

export async function getWorkspaceInfo(name) {
  return get(`/orgs/${name}`).then((res) => res.data);
}

export async function editWorkspace(data) {
  const o = userStore.org;
  return put(`/orgs/${o}`, data);
}
