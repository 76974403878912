import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@compodium/ui-lib/material';
import Dialog from '../Modals/Dialog';
import { logoutAll } from '../../actions/UserActions';

export default function LogoutAll() {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const onConfirm = () => {
    logoutAll();
    setConfirm(false);
  };
  return (
    <>
      <Button variant="contained" onClick={() => setConfirm(true)}>
        {t('logout_all')}
      </Button>
      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        onConfirm={onConfirm}
        title={t('logout_all')}
      >
        {t('logout_all_confirm')}
      </Dialog>
    </>
  );
}
