import { useEffect, useState } from 'react';

export default function useIntervalCounter(limit, interval) {
  const [count, setCount] = useState(0);

  function increment() {
    setCount((prevCount) => prevCount + 1);
  }

  useEffect(() => {
    const to = setTimeout(() => {
      setCount(0);
    }, interval);

    return () => clearTimeout(to);
  }, [count]);

  return [count >= limit, increment, setCount];
}
