import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { addSnack } from '../actions/SnackActions';
import userStore from '../stores/UserStore';
import useUser from '../hooks/useUser';
import Loading from '../components/Loading';

export default function Login() {
  const { t } = useTranslation();
  const location = useLocation();
  const [authError, setAuthError] = useState(false);
  const { isLoggedIn } = useUser();
  const urlOnSuccessfulLogin = '/workspaces/pick';

  useEffect(() => {
    if (authError) return;
    if (isLoggedIn) {
      window.location.href = urlOnSuccessfulLogin;
    }
    window.location.href = `/api/login`;
  }, [isLoggedIn]);

  useEffect(() => {
    if (authError) return;
    if (isLoggedIn) {
      window.location.href = urlOnSuccessfulLogin;
    }

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('auth_success')) {
      setAuthError(false);
      queryParams.delete('auth_success');
      userStore.setIsLoggedIn(true);
      window.location.href = urlOnSuccessfulLogin;
    }
    if (queryParams.has('auth_error')) {
      setAuthError(true);
      queryParams.delete('auth_error');
      userStore.setIsLoggedIn(false);
      addSnack(t('authentication_failed'));
      window.location.href = '/login';
    }
  }, [location, isLoggedIn]);

  return <Loading />;
}
