import React from 'react';
import {
  Stack,
  Box,
  AccordionDetails,
  Button,
  Typography,
  useTheme,
  CardMedia,
} from '@compodium/ui-lib/material';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Wrapper from '../components/layout/Wrapper';
import { MEET_URL } from '../utils/api';

function Icon() {
  return (
    <CardMedia
      sx={{
        height: 45,
        width: 43,
      }}
      component="img"
      image="/img/rocket.png"
    />
  );
}

function Embedded() {
  const { t } = useTranslation();

  return (
    <Stack gap={0.5} alignItems="center">
      <Typography fontWeight="bold" textAlign="center">
        {t('next_step')} {t('generate_api_keys')}
      </Typography>
      <Typography maxWidth="360px" textAlign="center">
        {t('we_have_created_guide')}
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/embedded/configure"
        color="primary"
        sx={{
          mt: 2,
          maxWidth: { xs: '100%', md: 'fit-content' },
        }}
      >
        {t('get_started_title')}
      </Button>
    </Stack>
  );
}

function Meet() {
  const { t } = useTranslation();

  return (
    <Stack gap={0.5} alignItems="center">
      <Typography fontWeight="bold" textAlign="center">
        {t('next_step')} {t('have_a_meeting_exclamation')}
      </Typography>
      <Typography maxWidth="360px" textAlign="center">
        {t('as_easy_as_it_sounds')}
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to={MEET_URL}
        color="primary"
        sx={{
          mt: 2,
          maxWidth: { xs: '100%', md: 'fit-content' },
        }}
      >
        {t('meet_title')}
      </Button>
    </Stack>
  );
}

export default function PaymentConfirmation() {
  const { t } = useTranslation();
  const { type } = useParams();
  const theme = useTheme();

  return (
    <Wrapper>
      <Stack
        direction="row"
        textAlign="center"
        sx={{
          border: 1,
          borderColor: '#D9D9D9',
          mt: 10,
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            mt: 0,
          },
        }}
      >
        <Stack
          backgroundColor="grey.100"
          alignItems="center"
          sx={{
            p: 5,
            [theme.breakpoints.down('xl')]: {
              p: 3,
            },
          }}
          gap={4}
        >
          <div>
            <Typography variant="h4" sx={{ pt: 1 }}>
              {t('thank_you')}
            </Typography>
            <Typography fontWeight="bold" sx={{ pt: 1 }}>
              {t('lets_be_productive')}
            </Typography>
          </div>
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
            sx={{
              [theme.breakpoints.down('sm')]: {
                gap: 2,
              },
            }}
          >
            <Box sx={{ borderBottom: 1, width: 100, borderColor: '#D9D9D9' }} />
            <Icon sx={{ p: 1 }} />
            <Box sx={{ borderBottom: 1, width: 100, borderColor: '#D9D9D9' }} />
          </Stack>
          <Typography maxWidth="360px" sx={{ pb: 2 }}>
            {t('view_or_change_subscription')}
          </Typography>
        </Stack>
        <Stack
          sx={{
            p: 5,
            justifyContent: 'center',
            [theme.breakpoints.down('xl')]: {
              p: 3,
            },
          }}
        >
          <AccordionDetails>
            {type === 'meet' && <Meet />}
            {type === 'embedded' && <Embedded />}
          </AccordionDetails>
        </Stack>
      </Stack>
    </Wrapper>
  );
}
