import { useEffect, useState } from 'react';
import useUser from './useUser';

export default function useOrgChanged() {
  const { org } = useUser();
  const [prevOrg, setPrevOrg] = useState(org);

  useEffect(() => {
    setPrevOrg(org);
  }, [org]);

  return prevOrg !== org;
}
