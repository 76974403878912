import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack, TextField, Text } from '@compodium/ui-lib/material';
import BackIcon from '@compodium/ui-lib/icons/ChevronLeft';
import Wrapper from '../components/Workspaces/Wrapper';
import { sendRequest as sendRequestToServer } from '../actions/WorkspaceActions';
import { toOrgNameFmt } from '../utils/functions';

export default function JoinWorkspace() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [err, setErr] = useState('');
  const nav = useNavigate();

  function sendRequest() {
    sendRequestToServer(name)
      .then(() => {
        nav(`../request-sent?name=${name}`);
      })
      .catch((e) => {
        const { status } = e.response || {};
        if (status === 404) {
          setErr(t('workspace_not_found'));
        } else if (status === 409) {
          nav(`../request-sent?name=${name}`);
        }
      });
  }

  const handle = (e) => {
    const { value } = e.target;
    setName(toOrgNameFmt(value));
  };

  return (
    <Wrapper>
      <Stack gap={1}>
        <Stack gap={0.5} alignItems="start">
          <Button onClick={() => nav('/workspaces')} startIcon={<BackIcon />}>
            {t('back_to_workspaces')}
          </Button>
          <Text variant="h4">{t('join_workspace_title')}</Text>
        </Stack>
        {!!err && <Text color="error.main">{err}</Text>}
        <Stack gap={1}>
          <TextField
            label={t('workspace_name')}
            value={name}
            onChange={handle}
            onKeyPress={(e) => e.key === 'Enter' && sendRequest()}
          />
          <Button
            variant="contained"
            onClick={() => sendRequest()}
            disabled={name.length < 1}
            sx={{ width: 106, height: 48 }}
          >
            {t('continue')}
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
}
