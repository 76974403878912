import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@compodium/ui-lib/material';
import useIsMobile from '../../hooks/useIsMobile';
import { dur, toHuman } from '../../utils/time';

export default function MeetingVideoFooter({ meeting }) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { createdAt, stoppedAt } = meeting;

  return (
    <Stack direction={isMobile ? 'column' : 'row'} gap={{ xs: 1, sm: 2 }}>
      <Stack direction="row" gap={0.5}>
        <Text variant="body2" color="text.secondary">
          {t('started')}
        </Text>
        <Text variant="subtitle2">{toHuman(createdAt)}</Text>
      </Stack>
      <Stack direction="row" gap={0.5}>
        <Text variant="body2" color="text.secondary">
          {t('duration')}
        </Text>
        <Text variant="subtitle2">{dur(createdAt, stoppedAt)}</Text>
      </Stack>
    </Stack>
  );
}
