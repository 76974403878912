import { useTranslation } from 'react-i18next';
import { Button, Stack, TextField } from '@compodium/ui-lib/material';
import DeleteIcon from '@compodium/ui-lib/icons/Delete';
import RoleSelect from '../RoleSelect';
import Dialog from '../Modals/Dialog';

export default function MemberDialog({
  open,
  onClose,
  myId,
  member,
  onRoleChange,
  onDelete,
}) {
  const { t } = useTranslation();

  const { id, isAdmin, firstName, lastName } = member || {};
  const hasName = [firstName, lastName].some(Boolean);
  const name = [firstName, lastName].join(' ');
  const notMe = myId !== id;

  return (
    <Dialog open={open} onClose={onClose} title={name} hideSubmit>
      <Stack gap={1} pt={1}>
        {hasName && <TextField readOnly label={t('name')} value={name} />}

        <TextField readOnly label={t('email')} value={member?.email} />

        <RoleSelect
          value={isAdmin ? 'admin' : 'user'}
          renderValue={(value) => t(value)}
          onChange={(e) => onRoleChange(id, e.target.value)}
          disabled={!notMe}
        />

        <Button
          variant="outlined"
          color="error"
          disabled={!notMe}
          startIcon={<DeleteIcon />}
          onClick={onDelete}
          sx={{ textTransform: 'none' }}
        >
          {t('remove_user_button')}
        </Button>
      </Stack>
    </Dialog>
  );
}
