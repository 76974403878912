import React, { useState, useEffect } from 'react';
import { Snackbar, IconButton } from '@compodium/ui-lib/material';
import CloseIcon from '@compodium/ui-lib/icons/Close';
import snackStore from '../stores/SnackStore';

function SnackbarComponent() {
  const [snacks, setSnacks] = useState([]);
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    snackStore.clear();
  }
  useEffect(() => {
    const subscription = snackStore.snacks$.subscribe((data) => {
      setSnacks(data);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!snacks) return null;
  return (
    <>
      {snacks.map((snack) => (
        <Snackbar
          key={snack.msg}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          message={snack.msg}
          open
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(e, r) => handleClose(e, r)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ))}
    </>
  );
}

export default SnackbarComponent;
