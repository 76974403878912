import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemText,
  TableCell,
  IconButton,
  Stack,
  Tooltip,
} from '@compodium/ui-lib/material';
import CheckIcon from '@compodium/ui-lib/icons/Check';
import CloseIcon from '@compodium/ui-lib/icons/Close';

function AcceptButton({ onClick, disabled }) {
  const { t } = useTranslation();

  const button = (
    <IconButton
      edge="end"
      aria-label="accept"
      onClick={onClick}
      disabled={disabled}
    >
      <CheckIcon />
    </IconButton>
  );

  if (disabled) {
    return (
      <Tooltip
        title={t('max_users_reached')}
        placement="top"
        disableInteractive
      >
        <span>{button}</span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={t('add_more_users')} placement="top">
      <span>{button}</span>
    </Tooltip>
  );
}

export default function useRequestList({
  onAccept,
  onDecline,
  acceptDisabled,
}) {
  const { t } = useTranslation();
  const headers = [t('name'), t('email'), t('actions')];

  const renderRow = (item) => (
    <>
      <TableCell>{`${item.user.firstName} ${item.user.lastName}`}</TableCell>
      <TableCell>{item.user.email}</TableCell>
      <TableCell>
        <Stack direction="row" gap={1}>
          <AcceptButton
            onClick={() => onAccept(item)}
            disabled={acceptDisabled}
          />
          <IconButton
            edge="end"
            aria-label="decline"
            onClick={() => onDecline(item)}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </TableCell>
    </>
  );

  const renderItem = (item) => (
    <ListItem>
      <ListItemText
        primary={`${item.user.firstName} ${item.user.lastName}`}
        secondary={item.user.email}
      />
      <Stack direction="row" gap={1}>
        <AcceptButton
          onClick={() => onAccept(item)}
          disabled={acceptDisabled}
        />
        <IconButton
          edge="end"
          aria-label="decline"
          onClick={() => onDecline(item)}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </ListItem>
  );

  return {
    headers,
    renderRow,
    renderItem,
  };
}
