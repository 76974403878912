import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text, Card, CardContent } from '@compodium/ui-lib/material';
import Title from '../../../components/Title';
import ViewIntegrationDialog from '../../../components/Integrations/ViewIntegrationDialog';
import useIsMobile from '../../../hooks/useIsMobile';
import useUser from '../../../hooks/useUser';
import useIntegration from '../../../hooks/useIntegration';
import useIntegrationList from '../../../components/Integrations/useIntegrationList';
import {
  getIntegrations,
  createIntegration,
  createTokenIntegration,
  deleteIntegration,
} from '../../../actions/IntegrationActions';
import { addSnack } from '../../../actions/SnackActions';
import DeleteDialog from '../../../components/Modals/DeleteDialog';
import { List, Table } from '../../../components/list';
import userStore from '../../../stores/UserStore';

export default function ActiveAPIIntegrations() {
  const { t } = useTranslation();
  const [toDelete, setToDelete] = useState(null);
  const [selected, setSelected] = useState(null);
  const isMobile = useIsMobile();
  const { permissions } = useUser();
  const { integrations, prefix } = useIntegration();
  const { renderRow, renderMobileItem, headers } = useIntegrationList({
    onDelete: setToDelete,
    onClick: setSelected,
  });

  const refetch = () =>
    getIntegrations().catch((e) => {
      const error = e?.response?.data?.detail || e?.message;
      addSnack(
        `Error${e?.response?.status ? ` (${e.response.status})` : ''}: ${error}`
      );
    });

  useEffect(() => {
    if (!permissions?.read?.integrations) {
      return;
    }
    refetch();
  }, [permissions]);

  const onCreate = () => {
    createIntegration();
  };

  const closeDelete = () => setToDelete(null);

  const onDelete = () =>
    deleteIntegration(toDelete.apiKey).then(closeDelete).then(refetch);

  return (
    <Card
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: '8px',
        textDecoration: 'none',
        width: { xs: '100%', sm: '100%' },
        height: { xs: '100%', sm: '100%' },
      }}
    >
      <CardContent>
        <Text variant="subtitle1" color="text.primary">
          {t('active_api_integrations')}
        </Text>
        <Text variant="body1" color="text.secondary" sx={{ mt: 1, mb: 1 }}>
          Prefix: {prefix}
        </Text>
        <Title prefix={prefix} onCreate={onCreate} />
        {isMobile ? (
          <List data={integrations} renderItem={renderMobileItem} />
        ) : (
          <Table headers={headers} data={integrations} renderRow={renderRow} />
        )}
        <DeleteDialog
          open={Boolean(toDelete)}
          onConfirm={onDelete}
          onCancel={closeDelete}
        >
          {t(`confirm_delete {{id}}`, { id: toDelete?.apiKey })}
        </DeleteDialog>

        <ViewIntegrationDialog
          integration={selected}
          onClose={() => setSelected(null)}
        />
        <br />
        <Button
          sx={{
            mt: 1,
            minWidth: { md: '10%' },
            maxWidth: { xs: '100%', md: 'fit-content' },
          }}
          onClick={onCreate}
          variant="contained"
        >
          {t('generate_key')}
        </Button>
        {(userStore.org === 'compodium' ||
          window.DEBUG ||
          process.env.NODE_ENV === 'development') && (
          <Button
            sx={{
              mt: 1,
              minWidth: { md: '10%' },
              maxWidth: { xs: '100%', md: 'fit-content' },
            }}
            onClick={createTokenIntegration}
            variant="contained"
          >
            Generate token integration (local or compodium workspace only)
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
