import { useEffect } from 'react';
import { logout } from '../actions/UserActions';

export default function Logout() {
  useEffect(() => {
    logout();
  }, []);

  return null;
}
