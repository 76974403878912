import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const DISALLOWED_PROPERTIES = ['value', 'initialState', 'constructor'];

export default function createStore(initialState) {
  return class {
    initialState = { ...initialState };

    constructor() {
      const observables = [];
      const keys = [];

      Object.keys(this.initialState).forEach((key) => {
        if (DISALLOWED_PROPERTIES.includes(key)) {
          throw new Error(`"${key}" is not allowed as a property.`);
        }

        const ob = new BehaviorSubject(this.initialState[key]);
        this[`${key}$`] = ob;
        observables.push(ob);
        keys.push(key);

        Object.defineProperty(this, key, {
          set: (x) => {
            this[`${key}$`].next(x);
          },
          get: () => this[`${key}$`].value,
        });
      });

      this.data$ = combineLatest(...observables, (...values) => {
        const rv = {};

        keys.forEach((key, i) => {
          rv[key] = values[i];
        });

        return rv;
      }).pipe(debounceTime(0));
    }

    get value() {
      const rv = {};
      Object.keys(this.initialState).forEach((key) => {
        rv[key] = this[key];
      });
      return rv;
    }

    clear() {
      Object.keys(this.initialState).forEach((x) => {
        this[x] = this.initialState[x];
      });
    }
  };
}
