import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './lang/en/en.json';
import sv from './lang/sv/sv.json';

const resources = {
  en: { translation: en },
  sv: { translation: sv },
};

i18n.use(detector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  saveMissing: true,
});

export default i18n;
