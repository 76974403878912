import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@compodium/ui-lib/material';
import Wrapper from '../components/layout/Wrapper';
import {
  EmbeddedPricingTable,
  MeetFreeBanner,
  MeetPricingTable,
  SubscriptionsDetails,
  Title,
} from '../components/Subscriptions';
import useUser from '../hooks/useUser';

export default function Subscriptions() {
  const { t } = useTranslation();
  const { orgObj, permissions } = useUser();
  const workspaceId = orgObj?.id;
  const { subscriptions } = orgObj || {};
  const { isAdmin } = permissions || {};
  const { meet, embedded } = subscriptions || {};
  const hasMeetFree = meet?.origin === 'free';
  const hasMeet = !hasMeetFree && !!meet;
  const hasEmbedded = !!embedded;
  const hasSubscription = hasMeet || hasEmbedded;
  const showAvailablePlans = !hasMeet || !hasEmbedded;

  return (
    <Wrapper maxWidth="xl">
      <Stack gap={2}>
        <Title />
        {hasMeetFree && <MeetFreeBanner />}
        <Stack gap={1}>
          {hasSubscription && (
            <SubscriptionsDetails isAdmin={isAdmin} sub={subscriptions} />
          )}
          {showAvailablePlans && (
            <Stack gap={0.5}>
              <Text variant="h5" color="primary.main">
                {t('available_plans_title')}
              </Text>
              <Text variant="subtitle2" color="text.secondary">
                {t('available_plans_desc')}
              </Text>
            </Stack>
          )}
          {!hasMeet && <MeetPricingTable clientReferenceId={workspaceId} />}
          {!hasEmbedded && (
            <EmbeddedPricingTable clientReferenceId={workspaceId} />
          )}
        </Stack>
      </Stack>
    </Wrapper>
  );
}
