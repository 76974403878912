import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Stack, Text } from '@compodium/ui-lib/material';
import { CheckCircle as CheckCircleIcon } from '@compodium/ui-lib/icons';
import OptionCard from '../components/OptionCard';
import Wrapper from '../components/Workspaces/Wrapper';
import { getWorkspaceInfo } from '../actions/WorkspaceActions';

export default function RequestSent() {
  const { t } = useTranslation();
  const [group, setGroup] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');
    if (!name) return;
    getWorkspaceInfo(name).then(({ group: g }) => {
      setGroup(g);
    });
  }, []);

  const { displayName, name, memberCount } = group || {};

  const initials =
    displayName || name
      ? (displayName.split(' ') || name.split('-') || [])
          .map((n) => n[0])
          .join('')
      : '';

  return (
    <Wrapper>
      <Stack gap={2.5}>
        <Stack direction="row" gap={1} alignItems="center">
          <CheckCircleIcon sx={{ color: 'success.main' }} />
          <Text variant="h4">{t('request_sent')}</Text>
        </Stack>

        <Stack gap={1}>
          <Text variant="body1">{t('request_sent_desc')}</Text>
          <OptionCard
            title={displayName || name}
            description={t(`members {{count}}`, { count: memberCount || 0 })}
            icon={<Avatar>{initials}</Avatar>}
          />
        </Stack>

        <Text variant="body1">{t('request_sent_desc_2')}</Text>
        <Button
          href="/"
          variant="contained"
          color="primary"
          sx={{ height: 48 }}
        >
          {t('go_to_portal')}
        </Button>
      </Stack>
    </Wrapper>
  );
}
