/* eslint-disable jsx-a11y/media-has-caption */
import { forwardRef } from 'react';
import Text from '@compodium/ui-lib/material/Text';
import { getRecDl } from '../../actions/MeetingActions';

const rnd = (from = 0, to = 10 ** 5) =>
  Math.floor(Math.random() * (to - from + 1) + from);

function MeetingVideo({ meeting }, ref) {
  const { id, playbackExists } = meeting;
  // v tells the backend to use the provided Range header
  const src = `${getRecDl(id)}?v&nonce=${rnd()}`;

  if (!playbackExists) {
    return <Text sx={{ py: 2 }}>Meeting not ready...</Text>;
  }

  return (
    <video key={id} ref={ref} controls playsInline height="auto" width="100%">
      <source src={src} type="video/mp4" />
    </video>
  );
}

export default forwardRef(MeetingVideo);
