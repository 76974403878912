import { useMemo } from 'react';
import { darkMode, lightMode } from '@compodium/ui-lib/theme';
import { useTranslation } from 'react-i18next';
import {
  CssBaseline,
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider as TP,
} from '@compodium/ui-lib/material';
import { enUS, svSE } from '@compodium/ui-lib/material/locale';

export const locales = {
  en: enUS,
  sv: svSE,
};

const blackish = {
  main: '#0e0c1d',
  contrastText: darkMode.palette.text.primary,
};
darkMode.palette.blackish = blackish;
lightMode.palette.blackish = blackish;

export default function ThemeProvider({
  darkmode = false,
  noBaseline = false,
  children,
}) {
  const { i18n } = useTranslation();

  const locale = useMemo(() => locales[i18n.language], [i18n.language]);

  const dark = useMemo(
    () => responsiveFontSizes(createTheme(darkMode, locale)),
    [locale]
  );
  const light = useMemo(
    () => responsiveFontSizes(createTheme(lightMode, locale)),
    [locale]
  );
  const theme = darkmode ? dark : light;

  return (
    <TP theme={theme}>
      <StyledEngineProvider injectFirst>
        {!noBaseline && <CssBaseline />}
        {children}
      </StyledEngineProvider>
    </TP>
  );
}
