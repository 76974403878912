import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
} from '@compodium/ui-lib/material';
import {
  Menu as MenuIcon,
  Notifications as BellIcon,
} from '@compodium/ui-lib/icons';
import ThemeProvider from '../ThemeProvider';
import UserCard from '../UserCard';
import Logo from '../Logo';
import useUser from '../../hooks/useUser';

const noop = () => {};

function MenuButton({ onClick }) {
  const { notifications } = useUser();
  const badgeContent = notifications?.length || 0;
  const badgeSx = {
    '& .MuiBadge-badge': { bottom: -13, top: 'unset' },
  };

  return (
    <Badge
      badgeContent={badgeContent}
      invisible={badgeContent === 0}
      color="primary"
      sx={badgeSx}
    >
      <IconButton onClick={onClick} margin="dense">
        <MenuIcon />
      </IconButton>
    </Badge>
  );
}

export default function Header({ isMobile, onMenuClick = noop }) {
  const { darkmode } = useUser();
  const sx = {
    backgroundColor: darkmode ? 'background.default' : 'blackish.main',
    height: 'var(--header-height)',
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    '& > *': { px: 1, py: 0.5 },
  };

  return (
    <ThemeProvider darkmode noBaseline>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={sx}
      >
        <Grid item xs={2} display={{ xs: 'inherit', md: 'none' }}>
          <MenuButton onClick={onMenuClick} />
        </Grid>
        <Grid item xs={6} sm={2} display="flex" justifyContent="center">
          <Logo darkmode variant={isMobile ? 'short' : 'long'} />
        </Grid>
        <Grid item md={8} display={{ xs: 'none', md: 'inherit' }} />
        <Grid item sm={2}>
          <Stack direction="row" gap={1} alignItems="center">
            <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
              <>
                <Link to="/notifications">
                  <BellIcon sx={{ color: 'text.secondary' }} />
                </Link>
                <Divider orientation="vertical" flexItem />
              </>
            </Box>
            <UserCard variant={isMobile ? 'short' : 'long'} />
          </Stack>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
