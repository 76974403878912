import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import {
  DeleteAccount,
  PasswordForm,
  LogoutAll,
  Otp,
  UserForm,
} from '../components/UserSettings';
import OptionCard from '../components/OptionCard';
import Wrapper from '../components/layout/Wrapper';
import useUser from '../hooks/useUser';
import { addSnack } from '../actions/SnackActions';
import { updateMe, getMe } from '../actions/UserActions';

function Title() {
  const { t } = useTranslation();
  return (
    <Stack gap={0.5}>
      <Text variant="h4">{t('settings_title')}</Text>
      <Text variant="body1" color="text.secondary">
        {t('settings_desc')}
      </Text>
    </Stack>
  );
}

function UserCard() {
  const { t } = useTranslation();
  const { firstName, lastName } = useUser();
  const name = `${firstName} ${lastName}`;
  const initials = firstName[0] + lastName[0];

  return (
    <OptionCard
      title={name}
      description={t('change_profile')}
      icon={<Avatar sx={{ width: 48, height: 48 }}>{initials}</Avatar>}
      noBorder
    >
      <UserForm user={{ firstName, lastName }} />
    </OptionCard>
  );
}

function EmailSettingsDesc() {
  const { t } = useTranslation();
  const { firstName, lastName, emailSubscription } = useUser();

  const onChange = async (e) => {
    try {
      await updateMe({
        firstName,
        lastName,
        emailSubscription: e.target.checked,
      });

      await getMe();
    } catch (err) {
      const { detail, message } = err?.response?.data || {};
      const msg = detail || message || t('error');
      addSnack(msg);
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      checked={emailSubscription}
      onChange={onChange}
      label={t('email_subscription')}
      slotProps={{ typography: { variant: 'caption' } }}
    />
  );
}

function SettingsOptions() {
  const { t, i18n } = useTranslation();
  const { email } = useUser();
  const langs = {
    en: 'English',
    sv: 'Svenska',
  };
  const lang = langs[i18n.language];
  const tz = DateTime.now().toFormat('ZZ');

  const settings = [
    {
      title: t('settings_email_title'),
      desc: t('settings_email_desc {{email}}', { email }),
      noIcon: true,
      children: <EmailSettingsDesc />,
    },
    {
      title: t('settings_password_title'),
      desc: t('settings_password_desc'),
      noIcon: true,
      children: <PasswordForm />,
    },
    {
      title: t('otp_title'),
      desc: t('otp_desc'),
      noIcon: true,
      children: <Otp />,
    },
    {
      title: t('settings_intl_title'),
      desc: t('settings_intl_desc {{lang}} {{tz}}', {
        lang,
        tz,
      }),
      noIcon: true,
    },
    {
      title: t('settings_signout_title'),
      desc: t('settings_signout_desc'),
      noIcon: true,
      children: <LogoutAll />,
    },
    {
      title: t('delete_account_title'),
      desc: t('delete_account_desc'),
      noIcon: true,
      children: <DeleteAccount />,
    },
  ];

  return (
    <Stack gap={1}>
      {settings.map(({ title, desc, icon, noIcon, children }) => (
        <OptionCard
          key={title}
          description={desc}
          title={title}
          icon={icon}
          noIcon={noIcon}
        >
          {children}
        </OptionCard>
      ))}
    </Stack>
  );
}

export default function UserSettings() {
  return (
    <Wrapper>
      <Stack gap={2}>
        <Title />
        <UserCard />
        <SettingsOptions />
      </Stack>
    </Wrapper>
  );
}
