/* eslint-disable no-constant-condition */
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Stack,
  Text,
  Typography,
} from '@compodium/ui-lib/material';
import Wrapper from '../components/layout/Wrapper';
import HighFive from '../components/Home/HighFive';
import useUser from '../hooks/useUser';
import OptionCard from '../components/OptionCard';
import { MEET_URL } from '../utils/api';
import useIsMobile from '../hooks/useIsMobile';

function Title() {
  const { t } = useTranslation();
  const { firstName, orgObj } = useUser();
  const orgName = orgObj?.name;

  return (
    <Stack>
      <Stack direction="row" gap={0.2}>
        <Text variant="h4">{t('welcome {{name}}', { name: firstName })}</Text>
        <HighFive />
      </Stack>
      <Text color="text.secondary">
        <Trans i18nKey="dashboard_subtitle {{orgName}}" values={{ orgName }} />{' '}
        (
        <Text component={Link} to="/workspaces" color="primary">
          <strong>{t('switch_org')}</strong>)
        </Text>
      </Text>
    </Stack>
  );
}

function WorkspaceTitle() {
  const { orgObj } = useUser();

  return (
    <Text>
      <strong>{orgObj.name}</strong>{' '}
    </Text>
  );
}

function YourWorkspace() {
  const { t } = useTranslation();
  const {
    orgObj,
    permissions: { isAdmin },
  } = useUser();

  const { memberCount } = orgObj || {};

  const options = [
    {
      key: 'group',
      title: <WorkspaceTitle />,
      description: t('users {{count}}', { count: memberCount }),
      link: isAdmin ? '/admin' : '',
      icon: (
        <AvatarGroup max={3}>
          <Avatar />
          <Avatar />
          <Avatar />
        </AvatarGroup>
      ),
    },
  ];

  return (
    <Stack gap={1}>
      {options.map(
        ({ key, title, description, link, icon, external = false }) => (
          <OptionCard
            key={key}
            title={title}
            description={description}
            link={link}
            icon={icon}
            external={external}
          />
        )
      )}
    </Stack>
  );
}

function SubCard({
  image,
  icon,
  title,
  description,
  buttonName,
  path,
  width = '120%',
}) {
  const isMobile = useIsMobile('(max-width: 900px)');
  function getTarget() {
    return path.startsWith('/') ? '' : '_blank';
  }
  return (
    <Card
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: '15px',
        boxShadow: '0 0 10px lightgrey',
        textDecoration: 'none',
        height: { sm: 270, xs: 250 },
        width: { xs: '100%', md: 400 },
        '&:active': {
          boxShadow: { xs: '0 0 10px #000000', sm: '0 0 10px #000000' },
          transition: { sm: 'all 0.5s ease' },
        },
      }}
      component={isMobile ? 'a' : ''}
      href={path}
      target={getTarget()}
    >
      {image ? (
        <CardMedia
          sx={{ height: 140, width: { width }, filter: 'brightness(40%)' }}
          component="img"
          image={image}
          title=""
        />
      ) : null}
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <CardContent sx={{ height: 130 }}>
          <Stack direction="row">
            <CardMedia
              component="img"
              image={icon}
              sx={{
                position: 'relative',
                color: 'text.secondary',
                fontSize: '2rem',
                p: 0,
                pr: 0.5,
                height: 30,
                width: 35,
              }}
            />
            <Typography variant="h6" color="text.secondary">
              {title}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}
          >
            <Stack
              sx={{
                width: { xs: '100%', sm: '59%' },
                display: 'table-cell',
              }}
            >
              <Typography gutterBottom variant="h6" component="div">
                {description}
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: { xs: 'none', lg: 'inherit', xl: 'inherit' },
                float: 'right',
                position: 'relative',
                top: '14',
              }}
            >
              <Button
                component={Link}
                to={path}
                target={path.startsWith('/') ? '_self' : '_blank'}
                variant="contained"
                color="primary"
                sx={{ minWidth: 140, position: 'relative', top: '14px' }}
              >
                {buttonName}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
}

function CardsList({ cards, title }) {
  return (
    <Stack gap={1.5} pb={1}>
      <Stack direction="row" gap={0.5}>
        <Text variant="h6">{title}</Text>
      </Stack>
      <Stack direction="row" gap={1.5} flexWrap="wrap">
        {cards.map((item) => (
          <SubCard
            key={item.key}
            image={item.image}
            icon={item.icon}
            title={item.title}
            description={item.description}
            buttonName={item.buttonName}
            path={item.path}
            width={item.width}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function ShowCards() {
  const { t } = useTranslation();
  const {
    permissions: { read },
  } = useUser();

  const primaryCards = [
    {
      key: 'meet',
      image: '/img/phone.png',
      icon: 'svgs/meet-primary.svg',
      title: t('meet'),
      description: t('have_a_meeting'),
      buttonName: t('meet'),
      path: MEET_URL,
    },
    {
      key: 'past_meetings',
      image: '/img/angled-laptop.png',
      icon: 'svgs/meet-primary.svg',
      title: t('past_meetings'),
      description: t('view_saved_meetings'),
      buttonName: t('meetings_title'),
      path: '/meetings',
    },
  ];

  const secondaryCards = [
    {
      key: 'subscription',
      image: '/img/phone.png',
      icon: 'svgs/meet-primary.svg',
      title: t('subscription_title'),
      description: t('need_more_features'),
      buttonName: t('upgrade_title'),
      path: '/subscriptions',
    },
    {
      key: 'docs',
      image: '/img/get-started-guide.png',
      icon: 'svgs/meet-primary.svg',
      title: t('docs'),
      description: t('get_started_by_reading_docs'),
      buttonName: t('guides'),
      path: 'https://docs.compodium.com/docs/Get%20started',
      width: '100%',
    },
    {
      key: 'embedded',
      image: '/img/code.png',
      icon: 'img/logo-embedded.png',
      title: t('embedded_title'),
      description: t('try_out_embedded'),
      buttonName: t('get_started_title'),
      path: read?.integrations ? '/embedded' : '/subscriptions',
    },
  ];

  return (
    <Stack gap={1.5} pb={1}>
      <CardsList
        cards={primaryCards}
        title={t('what_do_you_want_to_do_today')}
      />
      <CardsList cards={secondaryCards} title={t('more')} />
    </Stack>
  );
}

function NoWorkspaces() {
  const { t } = useTranslation();
  const { firstName } = useUser();

  const links = [
    {
      key: 'create',
      title: t('create_workspace'),
      description: t('create_workspace_desc'),
      buttonName: t('create'),
      path: '/workspaces/setup',
    },
    {
      key: 'join',
      title: t('join_workspace'),
      description: t('join_workspace_desc'),
      buttonName: t('join'),
      path: '/workspaces/join',
    },
  ];

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={0.2}>
        <Text variant="h4">{t('welcome {{name}}', { name: firstName })}</Text>
        <CardMedia
          sx={{ height: 50, width: 50, position: 'relative' }}
          component="img"
          image="/img/high-five.png"
          title=""
        />
      </Stack>

      <Text>{t('no_workspaces_text')}</Text>

      <Stack direction="row">
        {links.map((item) => (
          <Card
            key={item.key}
            sx={{ width: { xs: '100%', sm: '50%' }, m: 0.5, p: 0.5 }}
          >
            <CardHeader title={item.title} />
            <CardContent>
              <Text variant="body2" color="text.secondary">
                {item.description}
              </Text>
            </CardContent>
            <CardActions>
              <Button variant="contained" component={Link} to={item.path}>
                {item.buttonName}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
}

export default function Home() {
  const { orgs } = useUser();
  const hasWorkspaces = orgs.length > 0;
  return (
    <Wrapper maxWidth="xl">
      {hasWorkspaces ? (
        <Stack gap={2}>
          <Title />
          <YourWorkspace />
          <Stack gap={1}>
            <ShowCards />
          </Stack>
        </Stack>
      ) : (
        <NoWorkspaces />
      )}
    </Wrapper>
  );
}
