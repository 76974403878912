import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import {
  ExpandMore as ExpandIcon,
  ChevronRight as ChevronRightIcon,
} from '@compodium/ui-lib/icons';
import CamIcon from './CamIcon';

function Summary({
  component,
  noBorder,
  link,
  icon,
  noIcon = false,
  title,
  description,
  goto,
  isAccordion,
}) {
  const sx = {
    p: isAccordion ? 0 : 1,
    color: 'text.primary',
    borderWidth: noBorder ? 0 : 1,
    borderStyle: 'solid',
    borderRadius: '1rem',
    borderColor: 'divider',
    '&:hover': link
      ? {
          borderColor: 'primary.main',
          cursor: 'pointer',
        }
      : {},
    textDecoration: 'inherit',
  };

  const titleComponent =
    !!title && typeof title === 'string' ? (
      <Text variant="h6" sx={{ textTransform: 'none' }}>
        <Trans i18nKey={title} />
      </Text>
    ) : (
      title
    );
  const descComponent =
    !!description && typeof description === 'string' ? (
      <Text
        variant="body2"
        color="text.secondary"
        sx={{ textTransform: 'none' }}
      >
        <Trans i18nKey={description} />
      </Text>
    ) : (
      description
    );

  return (
    <Stack
      component={component}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      href={link}
      onClick={goto}
      sx={sx}
      gap={1}
    >
      <Stack gap={1} direction="row" alignItems="center">
        {noIcon ? '' : icon || <CamIcon />}
        <Stack justifyContent="center">
          {!!title && titleComponent}
          {!!description && descComponent}
        </Stack>
      </Stack>
      {!!link && <ChevronRightIcon />}
    </Stack>
  );
}

export default function OptionCard({
  icon,
  noIcon = false,
  title,
  description,
  link,
  noBorder = false,
  external = false,
  children,
}) {
  const nav = useNavigate();
  const goto = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (external) window.open(link, '_blank');
    else nav(link);
  };

  if (!children) {
    return (
      <Summary
        component={link ? 'a' : 'div'}
        noBorder={noBorder}
        link={link}
        icon={icon}
        noIcon={noIcon}
        title={title}
        description={description}
        goto={goto}
      />
    );
  }
  return (
    <Accordion
      elevation={0}
      sx={{
        color: 'text.primary',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '1rem',
        borderColor: 'divider',
        '&:before': { display: 'none' },
        '&:first-of-type': {
          borderRadius: '1rem',
        },
        '&:last-of-type': {
          borderRadius: '1rem',
        },
      }}
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandIcon />} sx={{ px: 1 }}>
        <Summary
          icon={icon}
          title={title}
          description={description}
          noBorder
          isAccordion
          noIcon={noIcon}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
