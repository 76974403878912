import { useMemo, useState } from 'react';

/* props: { total, page, pageSize } */
export default function useQuery(props) {
  const { total } = props;

  const [page, setPage] = useState(props?.page || 0);
  const [prevPage, setPrevPage] = useState(0);
  const [pageSize, setPageSize] = useState(props?.pageSize || 10);

  const offset = useMemo(() => page * pageSize, [page, pageSize]);
  const lastPage = useMemo(
    () => (total ? Math.ceil(total / pageSize) : null),
    [total, pageSize]
  );

  const onSetPage = (v) => {
    setPrevPage(page);
    setPage(v);
  };

  return {
    prevPage,
    page,
    setPage: onSetPage,
    pageSize,
    setPageSize,
    offset,
    lastPage,
  };
}
