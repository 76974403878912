import { Container, Divider, Stack } from '@compodium/ui-lib/material';
import Logo from '../../Logo';
import useIsMobile from '../../../hooks/useIsMobile';
import useUser from '../../../hooks/useUser';

export default function Wrapper({ children }) {
  const { darkmode } = useUser();
  const isMobile = useIsMobile();

  return (
    <Container
      maxWidth="xl"
      sx={{ height: '100vh', px: { xs: 0, sm: 'inherit' } }}
    >
      <Stack gap={1} py={1.5} height="100%">
        <Logo
          variant={isMobile ? 'short' : 'long'}
          darkmode={darkmode}
          sx={{ pl: 1, alignSelf: 'start' }}
        />
        {isMobile && <Divider />}

        <Container
          maxWidth="lg"
          sx={{
            width: '100%',
            flexGrow: 2,
            display: 'flex',
            alignItems: isMobile ? 'start' : 'center',
            justifyContent: 'start',
          }}
        >
          {children}
        </Container>
      </Stack>
    </Container>
  );
}
