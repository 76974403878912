import { Stack, Text } from '@compodium/ui-lib/material';
import useUser from '../hooks/useUser';

export default function BannerWithIcon({ icon, title, description }) {
  const { darkmode } = useUser();
  return (
    <Stack
      gap={0.5}
      sx={{
        borderRadius: '8px',
        p: 2,
        backgroundColor: darkmode ? 'grey.900' : 'grey.100',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {icon}
      <Text variant="h5">{title}</Text>
      <Text variant="body1">{description}</Text>
    </Stack>
  );
}
