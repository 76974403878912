import { createContext, useContext, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

const initValue = {
  from: DateTime.now().toJSDate(),
  to: DateTime.now().toJSDate(),
  fromMs: DateTime.now().toMillis(),
  toMs: DateTime.now().toMillis(),
  setFrom: () => {},
  setTo: () => {},
};

export const DateRangeContext = createContext(initValue);

export function DateRangeProvider({ children, init = {} }) {
  const [from, setFrom] = useState(init.from || DateTime.new().toJSDate());
  const [to, setTo] = useState(init.to || DateTime.new().toJSDate());
  const value = useMemo(
    () => ({
      from,
      to,
      fromMs: from.getTime(),
      toMs: to.getTime(),
      setFrom,
      setTo,
    }),
    [from, to]
  );

  return (
    <DateRangeContext.Provider value={value}>
      {children}
    </DateRangeContext.Provider>
  );
}

export const useDateRange = () => useContext(DateRangeContext);
