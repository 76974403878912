import { useEffect } from 'react';
import {
  CardMedia,
  Divider,
  Stack,
  Text,
  Tooltip,
} from '@compodium/ui-lib/material';
import useUser from '../../hooks/useUser';
import { addSnack } from '../../actions/SnackActions';
import { setShowDev } from '../../actions/UserActions';
import useIntervalCounter from '../../hooks/useIntervalCounter';

const CLICK_COUNT = 3;
const CLICK_INTERVAL_MS = 1000;

export default function HighFive() {
  const [clickCountReached, click, setClickCountReached] = useIntervalCounter(
    CLICK_COUNT,
    CLICK_INTERVAL_MS
  );
  const { showDev, currentGroupId, id } = useUser();

  const devData = (
    <Stack>
      <Text variant="caption">Workspace ID:</Text>
      <Text>{currentGroupId}</Text>
      <Divider flexItem />
      <Text variant="caption">userId:</Text>
      <Text>{id}</Text>
    </Stack>
  );

  useEffect(() => {
    if (clickCountReached) {
      addSnack(`Dev mode: ${showDev ? 'off' : 'on'}`, 'success');
      setShowDev(!showDev);
      setClickCountReached(0);
    }
  }, [clickCountReached]);

  const img = (
    <CardMedia
      sx={{
        height: 40,
        width: 38,
        position: 'relative',
        bottom: 5,
        marginLeft: 0.5,
      }}
      component="img"
      image="/img/high-five.png"
      title=""
      onClick={click}
    />
  );

  if (showDev) {
    return <Tooltip title={devData}>{img}</Tooltip>;
  }

  return img;
}
