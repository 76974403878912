/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import {
  InputAdornment,
  IconButton,
  TextField,
} from '@compodium/ui-lib/material';
import { Visibility, VisibilityOff } from '@compodium/ui-lib/icons';

function PasswordField(props) {
  const { disabled, readOnly = false } = props;

  const [show, setShow] = useState(false);
  const toggleVisibility = () => setShow(!show);
  return (
    <TextField
      variant="outlined"
      {...props}
      type={show ? 'text' : 'password'}
      InputProps={{
        ...(props?.InputProps || {}),
        readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={disabled}
              onClick={toggleVisibility}
              tabIndex={-1}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
export default PasswordField;
