import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { CircularProgress, Stack } from '@compodium/ui-lib/material';
import Wrapper from '../../components/layout/Wrapper';
import { Content } from '../../components/Embedded/Insights';
import {
  useDateRange,
  DateRangeProvider,
} from '../../contexts/DateRangeContext';
import useAnalytics from '../../hooks/useAnalytics';
import useUser from '../../hooks/useUser';
import { getEmbeddedAnalytics } from '../../actions/AnalyticsActions';
import Title from '../../components/Title';

function InsightsInner() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { embeddedAnalytics } = useAnalytics();
  const { org } = useUser();
  const { fromMs, toMs } = useDateRange();

  useEffect(() => {
    setLoading(true);
    getEmbeddedAnalytics({ fromMs, toMs }).finally(() => setLoading(false));
  }, [org, fromMs, toMs]);

  return (
    <Wrapper>
      <Stack gap={2}>
        <Title title={t('insights_title')} />
        {loading ? (
          <CircularProgress />
        ) : (
          <Content analytics={embeddedAnalytics?.usage?.values || []} />
        )}
      </Stack>
    </Wrapper>
  );
}

export default function Insights() {
  const from = DateTime.now().startOf('month').toJSDate();
  const to = DateTime.now().endOf('month').toJSDate();

  return (
    <DateRangeProvider init={{ from, to }}>
      <InsightsInner />
    </DateRangeProvider>
  );
}
