import { useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Text,
} from '@compodium/ui-lib/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@compodium/ui-lib/icons';
import DeleteDialog from '../Modals/DeleteDialog';
import useUser from '../../hooks/useUser';
import { getMe, deleteTotp, getTotpUrl } from '../../actions/UserActions';

function OtpItem({ item, onDelete }) {
  const { t } = useTranslation();

  return (
    <ListItem
      secondaryAction={
        <Tooltip title={t('delete_totp_tooltip')}>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemText
        primary={item.userLabel}
        secondary={DateTime.fromMillis(item.createdDate).toFormat('D TT')}
      />
    </ListItem>
  );
}

export default function Otp() {
  const [toDel, setToDel] = useState(null);
  const { t } = useTranslation();
  const { totp } = useUser();

  const clearToDel = () => setToDel(null);
  const onDelete = async () => {
    if (!toDel) return;
    await deleteTotp(toDel.id);
    await getMe();
    clearToDel();
  };

  return (
    <>
      <Stack alignItems="center">
        <Stack gap={1}>
          <Text variant="h6" alignSelf="start">
            {t('totp_registered_devices')}
          </Text>
          <Button
            href={getTotpUrl()}
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t('new_totp')}
          </Button>
        </Stack>
        {!!totp && (
          <List sx={{ maxWidth: 300, width: '100%' }}>
            {totp.map((item) => (
              <OtpItem
                key={item.id}
                item={item}
                onDelete={() => setToDel(item)}
              />
            ))}
          </List>
        )}
      </Stack>
      <DeleteDialog
        open={!!toDel}
        title={t('delete_totp_title')}
        onCancel={clearToDel}
        onConfirm={onDelete}
      >
        <Stack gap={1}>
          <Text>
            {t('delete_totp_text {{device}}', { device: toDel?.userLabel })}
          </Text>
          <Text fontWeight={600}>{t('delete_totp_text2')}</Text>
        </Stack>
      </DeleteDialog>
    </>
  );
}
