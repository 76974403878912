import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { Paper, Stack, Text } from '@compodium/ui-lib/material';
import SearchField from '../SearchBar';
import { List, Pagination, Table, TablePagination } from '../list';
import useRequestList from './useRequestList';
import useIsMobile from '../../hooks/useIsMobile';
import useQuery from '../../hooks/useQuery';
import useWorkspace from '../../hooks/useWorkspace';
import useUser from '../../hooks/useUser';
import { getOrgs } from '../../actions/UserActions';
import {
  getMembers,
  getRequests,
  replyToRequest,
} from '../../actions/WorkspaceActions';

function Title({ q, setQ, count }) {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <SearchField
        value={q}
        onChange={(e) => setQ(e.target.value)}
        sx={{ display: { xs: 'inherit', sm: 'none' } }}
      />

      <Stack direction="row" justifyContent="space-between">
        <Text variant="h5">{t('requests')}</Text>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          display={{ xs: 'none', sm: 'inherit' }}
        >
          <Text
            variant="subtitle1"
            color="text.secondary"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {t('requests {{count}}', { count })}
          </Text>

          <SearchField value={q} onChange={(e) => setQ(e.target.value)} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function Requests() {
  const isMobile = useIsMobile();
  const { requests, totalRequests: total } = useWorkspace();
  const { orgObj } = useUser();
  const { page, setPage, offset, pageSize, setPageSize } = useQuery({
    pageSize: 50,
    total,
  });
  const [rawQ, setQ] = useState('');
  const [q] = useDebounce(rawQ, 500);

  const { meet } = orgObj?.subscriptions || {};
  const hasMeet = !!meet;
  const hasSubscription = meet?.tier !== 'free';
  const acceptDisabled = hasMeet && !hasSubscription;

  const refetch = useCallback(
    async () => getRequests({ q, offset, limit: pageSize }),
    [q, offset, pageSize]
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onAccept = async (request) => {
    await replyToRequest(request.id, true);
    await Promise.all([getOrgs(), getMembers(), refetch()]);
  };

  const onDecline = async (request) => {
    await replyToRequest(request.id, false);
    await refetch();
  };

  const { headers, renderRow, renderItem } = useRequestList({
    onAccept,
    onDecline,
    acceptDisabled,
  });

  const filteredRequests = requests.filter((request) => {
    const { firstName, lastName, email } = request.user;
    const fields = [firstName, lastName, email];
    return fields
      .map((x) => x.toLowerCase())
      .some((x) => x.includes(q.toLowerCase()));
  });

  return (
    <Stack gap={0.5}>
      <Title q={rawQ} setQ={setQ} count={total} />
      {isMobile ? (
        <>
          <List data={filteredRequests} renderItem={renderItem} />
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            total={total}
          />
        </>
      ) : (
        <Paper>
          <Table
            data={filteredRequests}
            headers={headers}
            renderRow={renderRow}
            footer={
              <TablePagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={total}
              />
            }
          />
        </Paper>
      )}
    </Stack>
  );
}
