import createStore from '../utils/create-store';

export const MeetingStore = createStore({
  meeting: null,
  meetings: [],
  total: 0,
  loading: false,
});

export default new MeetingStore();
