/* eslint-disable no-console */
export default {
  log: (...a) => {
    if (window.DEBUG || process.env.NODE_ENV === 'development') {
      console.log(...a);
    }
  },
  error: (...a) => {
    if (window.DEBUG || process.env.NODE_ENV === 'development') {
      console.error(...a);
    }
  },
  warn: (...a) => {
    if (window.DEBUG || process.env.NODE_ENV === 'development') {
      console.warn(...a);
    }
  },
};
