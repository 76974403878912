import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Button, Text } from '@compodium/ui-lib/material';
import { toOrgNameFmt } from '../../../utils/functions';

export default function Step1({ initValue, submit, error }) {
  const { t } = useTranslation();
  const [name, setName] = useState(initValue || '');

  const handle = (e) => {
    const { value } = e.target;
    setName(toOrgNameFmt(value));
  };

  const onSubmit = () => {
    submit(name);
  };

  const submitDisabled = name.length < 3;

  const submitOnEnter = (e) => {
    if (!submitDisabled && e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Stack gap={1} alignItems="flex-start">
      <Text variant="h4">{t('setup_workspace_step_1_title')}</Text>
      <Text variant="body1">{t('setup_workspace_step_1_desc')}</Text>
      <TextField
        label={t('workspace_name')}
        value={name}
        onChange={handle}
        onKeyPress={submitOnEnter}
        error={!!error}
        helperText={error}
        autoFocus
      />
      <Button variant="contained" onClick={onSubmit} disabled={submitDisabled}>
        {t('create')}
      </Button>
    </Stack>
  );
}
