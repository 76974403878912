import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Text } from '@compodium/ui-lib/material';
import DeleteDialog from '../Modals/DeleteDialog';
import { deleteMe } from '../../actions/UserActions';

export default function DeleteAccount() {
  const { t } = useTranslation();
  const [toDelete, setToDelete] = useState(false);
  const [err, setErr] = useState('');

  const openDelete = () => setToDelete(true);
  const closeDelete = () => {
    setErr('');
    setToDelete(false);
  };
  const onDelete = () =>
    deleteMe()
      .then(closeDelete)
      .catch((e) => {
        const { status, data: { detail = '', groups = [] } = {} } =
          e?.response || {};
        if (status === 412) {
          setErr(
            t('delete_account_err {{reason}}', {
              reason: t('user_only_admin {{groups}} {{count}}', {
                groups: groups.join(', '),
                count: groups.length,
              }),
            })
          );
        } else {
          setErr(detail || 'Request failed');
        }
      });

  return (
    <Stack gap={0.75}>
      <Text variant="body1">{t('delete_account_desc_long')}</Text>
      <Box>
        <Button
          variant="contained"
          color="error"
          sx={{ height: 40, justifySelf: 'end' }}
          onClick={openDelete}
        >
          {t('delete_account')}
        </Button>
      </Box>
      <DeleteDialog
        open={toDelete}
        title={t('delete_account_title')}
        onConfirm={onDelete}
        onCancel={closeDelete}
      >
        <Stack gap={1}>
          {err ? <Text color="error">{err}</Text> : null}
          <Text>{t('delete_account_desc_long')}</Text>
        </Stack>
      </DeleteDialog>
    </Stack>
  );
}
