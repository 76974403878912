import Divider from '@compodium/ui-lib/material/Divider';
import MuiList from '@compodium/ui-lib/material/List';

export default function List({ data, renderItem, sx = {}, dividerSx = {} }) {
  return (
    <MuiList sx={sx}>
      {data
        .map(renderItem)
        .reduce((acc, a) => [...acc, <Divider sx={dividerSx} />, a], [])}
    </MuiList>
  );
}
