import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Divider,
  FormControl,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@compodium/ui-lib/material';
import {
  Add as AddIcon,
  ExpandMore as ArrowIcon,
} from '@compodium/ui-lib/icons';
import useUser from '../hooks/useUser';
import { setOrg, getOrgs } from '../actions/UserActions';

export default function OrgSelect({
  label,
  variant = 'outlined',
  hide = false,
  fullWidth = false,
  showAddOption = false,
}) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { orgs, currentGroupId } = useUser();

  useEffect(() => {
    getOrgs();
  }, []);

  const handle = (e) => {
    const groupId = e.target.value;
    const { name } = orgs.find((x) => x.id === groupId) || {};
    setOrg(name);
  };

  if (hide) return null;

  const labelId = 'org-select-label';

  const initials = (group) =>
    (group.displayName?.split(' ') || group.name.split('-'))
      .map((x) => x?.[0])
      .map((x) => x.toUpperCase())
      .join('');

  if (orgs.length === 0) return null;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <FormControl variant={variant} size="small" fullWidth={fullWidth}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={currentGroupId}
        onChange={handle}
        IconComponent={ArrowIcon}
        {...(variant === 'standard' ? { disableUnderline: true } : {})}
      >
        {orgs.map((x) => (
          <MenuItem key={x.id} value={x.id} disableGutters>
            <ListItem disablePadding>
              <ListItemIcon sx={{ px: 0.5 }}>
                <Avatar>{initials(x)}</Avatar>
              </ListItemIcon>
              <ListItemText
                primary={x.displayName || x.name}
                secondary={x.displayName ? x.name : ''}
              />
            </ListItem>
          </MenuItem>
        ))}
        {showAddOption && (
          <>
            <Divider />
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                nav('/workspaces');
              }}
            >
              <ListItem disablePadding>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={t('create_or_join_workspace')} />
              </ListItem>
            </MenuItem>
          </>
        )}
      </Select>
    </FormControl>
  );
}
