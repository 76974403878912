import { VegaLite } from 'react-vega';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export default function MinutesUsedGraph({ analytics }) {
  const { t } = useTranslation();
  const msToM = 1 / 1000 / 60;
  let data = analytics
    .map((x) => ({
      date: DateTime.fromISO(x.startedAt).toFormat('dd-MM-yyyy'),
      duration: x.meetingDuration * msToM,
    }))
    .reduce((acc, cur) => {
      if (acc[cur.date]) {
        acc[cur.date] += cur.duration;
      } else {
        acc[cur.date] = cur.duration;
      }
      return acc;
    }, {});
  data = Object.entries(data).reduce((acc, [date, duration]) => {
    acc.push({ date, duration });
    return acc;
  }, []);
  const xTitle = t('time_period');
  const yTitle = t('minutes_used');

  const spec = {
    width: 400,
    height: 200,
    data: { name: 'data' },
    layer: [{ mark: 'bar' }],
    encoding: {
      x: {
        field: 'date',
        type: 'temporal',
        title: xTitle,
        axis: {
          format: '%d-%m-%Y',
          tickCount: 'day',
        },
      },
      y: { field: 'duration', type: 'quantitative', title: yTitle },
    },
  };

  return (
    <VegaLite spec={spec} data={{ data }} actions={false} renderer="svg" />
  );
}
