import {
  Stack,
  Card,
  CardContent,
  Text,
  Button,
  Box,
} from '@compodium/ui-lib/material';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function InfoCardWithoutImage({ title, description, url, buttonName }) {
  const { t } = useTranslation();
  return (
    <Stack sx={{ width: { xs: '100%', sm: '100%', md: '75%' } }}>
      <Text variant="subtitle1" color="text.primary">
        {t(title)}
      </Text>
      <Text sx={{ mt: 1, mb: 1, mr: 2 }}>{t(description)}</Text>
      <br />
      <Button
        variant="contained"
        component={Link}
        to={url}
        sx={{
          backgroundColor: '#88DECB',
          color: 'text.primary',
          '&:hover': { backgroundColor: '#88DECB' },
          maxWidth: { xs: '100%', md: 'fit-content' },
        }}
      >
        {t(buttonName)}
      </Button>
    </Stack>
  );
}

export default function InfoCardWithButton({
  title,
  description,
  buttonName,
  url,
  icon = null,
}) {
  const { t } = useTranslation();

  return (
    <Card
      elevation={0}
      title={t(title)}
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: '8px',
        textDecoration: 'none',
        width: { xs: '100%', sm: '100%' },
        height: { xs: '100%', sm: '100%' },
      }}
    >
      <CardContent>
        <Stack justifyContent="left" align-items="center">
          {icon ? (
            <Stack direction="row">
              <InfoCardWithoutImage
                title={title}
                description={description}
                url={url}
                buttonName={buttonName}
              />
              <Stack
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'inherit' },
                  width: { md: '25%' },
                }}
              >
                <Box component="img" src={icon} />
              </Stack>
            </Stack>
          ) : (
            <InfoCardWithoutImage
              title={title}
              description={description}
              url={url}
              buttonName={buttonName}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
