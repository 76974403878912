import React from 'react';
import ReactDOM from 'react-dom/client';
import { Settings } from 'luxon';
import './i18n';
import App from './App';
import { StoreContextProvider } from './contexts/StoreContext';
import './index.css';

Settings.defaultLocale = 'sv';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreContextProvider>
      <App />
    </StoreContextProvider>
  </React.StrictMode>
);
