/* eslint-disable react/no-array-index-key */
import {
  LinearProgress,
  TableContainer,
  Table as MuiTable,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
} from '@compodium/ui-lib/material';

const defaultRenderHead = (x) => (
  <TableCell
    key={JSON.stringify(x)}
    sx={{ borderColor: 'divider', borderRightWidth: 1, fontWeight: 'bold' }}
  >
    {x}
  </TableCell>
);

export default function Table({
  data,
  headers,
  renderRow,
  loading = false,
  renderHead = defaultRenderHead,
  onClick = () => {},
  rowSx = {},
  footer,
}) {
  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>{(headers || []).map(renderHead)}</TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          ) : (
            (data || []).map((x, i) => (
              <TableRow key={i} onClick={() => onClick(x)} sx={rowSx}>
                {renderRow(x)}
              </TableRow>
            ))
          )}
        </TableBody>
        {!!footer && (
          <TableFooter>
            <TableRow>{footer}</TableRow>
          </TableFooter>
        )}
      </MuiTable>
    </TableContainer>
  );
}
