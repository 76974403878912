import { Stack } from '@compodium/ui-lib/material';
import { useTranslation } from 'react-i18next';
import InfoCardWithButton from '../../../components/InfoCardWithButton';
import Wrapper from '../../../components/layout/Wrapper';
import Title from '../../../components/Title';
import ActiveAPIIntegrations from './ActiveAPIIntegrations';

export default function Configure() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title title={t('configure_title')} />
      <Stack gap={1} sx={{ mt: 2 }}>
        <ActiveAPIIntegrations />
        <InfoCardWithButton
          title="get_started_guide"
          description="get_started_guide_description"
          buttonName="get_started"
          icon="/img/laptop.png"
          url="https://docs.compodium.com/docs/Get%20started"
        />
        <InfoCardWithButton
          title="follow_your_usage"
          description="follow_your_usage_description"
          buttonName="explore_insights"
          url="/embedded/insights"
        />
      </Stack>
    </Wrapper>
  );
}
