import { useTranslation } from 'react-i18next';
import {
  Switch,
  FormControlLabel,
  IconButton,
} from '@compodium/ui-lib/material';
import {
  LightMode as LightIcon,
  DarkMode as DarkIcon,
} from '@compodium/ui-lib/icons';
import useUser from '../hooks/useUser';
import userStore from '../stores/UserStore';

export default function Darkmode({ variant = 'long' }) {
  const { t } = useTranslation();
  const { darkmode } = useUser();
  const toggle = () => userStore.setDarkmode(!darkmode);

  if (variant === 'short') {
    return (
      <IconButton onClick={toggle}>
        {darkmode ? <DarkIcon /> : <LightIcon />}
      </IconButton>
    );
  }
  if (variant === 'long') {
    return (
      <FormControlLabel
        control={<Switch checked={darkmode} onChange={toggle} />}
        label={t(darkmode ? 'darkmode' : 'lightmode')}
        labelPlacement="start"
        sx={{ ml: 0 }}
      />
    );
  }
}
