import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Stack } from '@compodium/ui-lib/material';
import { getMe, updateMe } from '../../actions/UserActions';

export default function UserForm({ user }) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
  }, [user]);

  const handle = (key) => (e) => {
    switch (key) {
      case 'firstName':
        setFirstName(e.target.value);
        break;
      case 'lastName':
        setLastName(e.target.value);
        break;
      default:
        break;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateMe({ firstName, lastName }).then(getMe);
  };

  return (
    <Stack gap={1} widht="100%" maxWidth={300}>
      <TextField
        label={t('first_name')}
        value={firstName}
        onChange={handle('firstName')}
      />
      <TextField
        label={t('last_name')}
        value={lastName}
        onChange={handle('lastName')}
      />
      <Button variant="contained" onClick={onSubmit}>
        {t('save')}
      </Button>
    </Stack>
  );
}
