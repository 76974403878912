import { Stack, Text } from '@compodium/ui-lib/material';

export default function KeyValue({ title, value }) {
  return (
    <Stack>
      <Text variant="subtitle2" color="text.secondary">
        {title}
      </Text>
      <Text variant="h5" color="primary">
        {value}
      </Text>
    </Stack>
  );
}
