import { useTranslation } from 'react-i18next';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TableCell,
  Tooltip,
  Text,
} from '@compodium/ui-lib/material';
import DeleteIcon from '@compodium/ui-lib/icons/Delete';
import CopyIcon from '@compodium/ui-lib/icons/ContentCopy';
import { addSnack } from '../../actions/SnackActions';

export default function useIntegrationList({ onDelete, onClick }) {
  const { t } = useTranslation();

  const copy = (text, snack) => {
    navigator.clipboard.writeText(text || '');
    addSnack(snack);
  };

  const headers = [t('api_key_title'), t('secret_title'), ''];

  const renderMobileItem = (integration) => {
    const { apiKey, secret } = integration;
    return (
      <ListItem
        secondaryAction={
          <IconButton
            onClick={(e) => e.stopPropagation() || onDelete(integration)}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemButton onClick={() => onClick(integration)}>
          <ListItemText
            primary={`API key: ${apiKey}`}
            secondary={secret ? `secret: ${secret}` : null}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderRow = (integration) => {
    const { apiKey, secret } = integration;
    return (
      <>
        <Tooltip title={t('click_to_copy')}>
          <TableCell
            onClick={() => copy(apiKey, t('api_key_copied'))}
            style={{ cursor: 'pointer', width: '2rem' }}
            size="small"
          >
            <Stack direction="row" gap={0.5}>
              <Text
                variant="body1"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {apiKey}
              </Text>
              <CopyIcon sx={{ textAlign: 'center', width: 0.4 / 3 }} />
            </Stack>
          </TableCell>
        </Tooltip>
        <Tooltip title={secret ? t('click_to_copy') : ''}>
          <TableCell
            size="small"
            onClick={() => !!secret && copy(secret, t('secret_copied'))}
            style={{
              cursor: secret ? 'pointer' : 'unset',
              overflow: 'hidden',
              maxWidth: '2rem',
            }}
          >
            {!!secret && (
              <Stack direction="row" gap={0.5}>
                <Text
                  variant="body1"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {secret ?? ''}
                </Text>
                <CopyIcon />
              </Stack>
            )}
          </TableCell>
        </Tooltip>
        <TableCell size="small" style={{ textAlign: 'center' }}>
          <IconButton onClick={() => onDelete(integration)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return {
    headers,
    renderRow,
    renderMobileItem,
  };
}
