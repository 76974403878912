import { t } from 'i18next';
import { del, get, post, put, cancelCalls, addCtCheck } from '../utils/api';
import { addSnack } from './SnackActions';
import userStore from '../stores/UserStore';
import i18n from '../i18n';

export async function getMe() {
  const o = userStore.org;
  const params = {};
  if (o) {
    params.org = o;
  }

  userStore.loading = true;
  return get(`/me`, { params }).then((res) => {
    const {
      id,
      email,
      firstName,
      lastName,
      emailSubscription,
      currentGroupId,
      locale = 'en',
      totp,
    } = res.data?.user || {};
    userStore.id = id;
    userStore.email = email;
    userStore.firstName = firstName;
    userStore.lastName = lastName;
    userStore.setIsLoggedIn(true);
    userStore.permissions = res.data?.permissions || {};
    userStore.emailSubscription = emailSubscription;
    userStore.currentGroupId = currentGroupId;
    userStore.totp = totp || false;
    userStore.locale = locale;
    userStore.loading = false;

    if (locale) {
      i18n.changeLanguage(locale);
    }
  });
}

export async function updateMe({ firstName, lastName, emailSubscription }) {
  return put('/me', { firstName, lastName, emailSubscription });
}

export async function getOrgs() {
  cancelCalls('getOrgs');
  const cancelToken = addCtCheck('getOrgs');
  return get('/orgs', { cancelToken }).then((res) => {
    if (!res?.data) {
      return;
    }
    const { orgs } = res.data;
    userStore.orgs = orgs || [];
    const { currentGroupId } = userStore;
    let myOrg;

    if (orgs.length === 1 || !currentGroupId) {
      [myOrg] = orgs;
    } else {
      myOrg = orgs.find((x) => x.id === currentGroupId) || {};
      if (!myOrg) {
        [myOrg] = orgs;
      }
    }
    userStore.setOrganisation(myOrg?.name);
    userStore.setOrgsPopulated(true);
  });
}

export async function setOrg(orgName) {
  const orgObj = userStore.setOrganisation(orgName);
  userStore.permissions.isAdmin = orgObj?.isAdmin;
  if (!orgObj?.id) return;

  await put(`/me/group/${orgObj?.id}`);
}

export async function logout() {
  return post('/logout').finally(() => {
    userStore.setIsLoggedIn(false);
    userStore.clear();
    window.location.reload();
  });
}

export async function logoutAll() {
  return post('/logout-all').finally(() => {
    addSnack(t('logged_out_all'));
    userStore.setIsLoggedIn(false);
    userStore.clear();
    window.location.reload();
  });
}

export async function changePassword({ oldPassword, newPassword }) {
  return post('/change-password', { oldPassword, newPassword }).then(() => {
    addSnack(t('password_changed'));
  });
}

export async function deleteMe() {
  return del('/me').then(() => {
    addSnack(t('user_deleted'));
    userStore.setIsLoggedIn(false);
    userStore.clear();
    window.location.reload();
  });
}

export async function getNotifications() {
  return get('/notifications').then((res) => {
    if (!res.data) {
      return;
    }
    userStore.notifications = res.data.notifications || [];
  });
}

export async function acceptInvite(groupName) {
  return post(`/orgs/${groupName}/invites/accept`);
}

export async function denyInvite(groupName) {
  return post(`/orgs/${groupName}/invites/deny`);
}

export function clearOrg() {
  userStore.setOrganisation('');
}

export function getTotpUrl() {
  return '/api/me/totp';
}

export function deleteTotp(id) {
  return del(`/me/totp/${id}`);
}

export function putLocale(locale) {
  return put(`/me/locale/${locale}`);
}

export function setShowDev(value) {
  userStore.setShowDev(value);
}
