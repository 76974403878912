import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@compodium/ui-lib/material';
import { roles } from '../utils/constants';

export default function RoleSelect({
  value,
  renderValue = (v) => v,
  onChange,
  disabled,
  size = 'small',
  fullWidth = false,
  hiddenLabel = false,
}) {
  const { t } = useTranslation();

  if (!roles.includes(value)) {
    throw new Error(`Invalid role: ${value}`);
  }

  const label = t('role');

  return (
    <FormControl
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
    >
      {!hiddenLabel && <InputLabel id="role-select-label">{label}</InputLabel>}
      <Select
        labelId="role-select-label"
        label={hiddenLabel ? '' : label}
        value={value}
        renderValue={renderValue}
        onChange={onChange}
      >
        {roles.map((role) => (
          <MenuItem key={role} value={role}>
            {renderValue(role)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
