import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, Text } from '@compodium/ui-lib/material';
import LangIcon from '@compodium/ui-lib/icons/Language';
import { putLocale } from '../actions/UserActions';
import useUser from '../hooks/useUser';

const lngs = {
  en: {
    nativeName: 'English',
  },
  sv: {
    nativeName: 'Svenska',
  },
};

function LocaleSelector({ variant = 'text' }) {
  const { t, i18n } = useTranslation();
  const { locale } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currentLocale = locale || i18n.resolvedLanguage;

  const onOpen = (e) => setAnchorEl(e.currentTarget);
  const onClose = () => setAnchorEl(null);
  const onChange = (lng) => {
    i18n.changeLanguage(lng);
    putLocale(lng).catch(() => {});
    onClose();
  };

  if (!['text', 'icon'].includes(variant)) {
    throw new Error(
      `Invalid variant '${variant}' for LocaleSelector. Must be 'text' or 'icon'`
    );
  }

  return (
    <>
      {variant === 'icon' && (
        <Button startIcon={<LangIcon />} onClick={onOpen} color="inherit">
          {currentLocale}
        </Button>
      )}
      {variant === 'text' && (
        <Text onClick={onOpen} color="inherit" sx={{ cursor: 'pointer' }}>
          {t('language')}
        </Text>
      )}
      <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} onClick={() => onChange(lng)}>
            {lngs[lng].flag}
            {lngs[lng].nativeName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
export default LocaleSelector;
