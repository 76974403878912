import { createContext, useContext, useMemo } from 'react';
import billingStore from '../stores/BillingStore';
import featureStore from '../stores/FeatureStore';
import integrationStore from '../stores/IntegrationStore';
import meetingStore from '../stores/MeetingStore';
import userStore from '../stores/UserStore';

const init = {
  billingStore,
  featureStore,
  integrationStore,
  meetingStore,
  userStore,
};

const StoreContext = createContext(init);
export default StoreContext;

export function StoreContextProvider({ children }) {
  const value = useMemo(
    () => ({
      billingStore,
      featureStore,
      integrationStore,
      meetingStore,
      userStore,
    }),
    [billingStore, featureStore, integrationStore, meetingStore, userStore]
  );
  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}

export const useStoreContext = () => useContext(StoreContext);
