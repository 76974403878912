import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  TableCell,
  Tooltip,
} from '@compodium/ui-lib/material';
import {
  Check as VerifiedIcon,
  Delete as DeleteIcon,
  DoNotDisturb as NotVerifiedIcon,
} from '@compodium/ui-lib/icons';
import RoleSelect from '../RoleSelect';

export default function useMemberList({
  myId,
  onDelete,
  onRoleChange,
  onClick,
}) {
  const { t } = useTranslation();

  const renderItem = useCallback((item) => {
    const { firstName, lastName, email, emailVerified, invitePending } = item;
    const name =
      firstName || lastName ? [firstName, lastName].join(' ') : '<no name>';
    return (
      <ListItem onClick={() => onClick(item)} sx={{ flexWrap: 'wrap' }}>
        <ListItemText
          primary={name}
          secondary={
            <Stack gap={0.5} direction="row" alignItems="center">
              {email}
              {emailVerified ? (
                <VerifiedIcon sx={{ fontSize: '1rem' }} />
              ) : (
                <NotVerifiedIcon sx={{ fontSize: '1rem' }} />
              )}
            </Stack>
          }
        />
        {invitePending ? <Chip label={t('invite_pending')} /> : null}
      </ListItem>
    );
  }, []);

  const renderRow = useCallback(
    (row) => {
      const {
        id,
        isAdmin,
        email,
        firstName,
        lastName,
        emailVerified,
        invitePending,
      } = row;

      const name = `${firstName || '-'} ${lastName || ''}`;

      const notMe = myId !== id;

      return (
        <>
          <TableCell>{name}</TableCell>
          <TableCell>
            <Stack direction="row" alignItems="center" gap={0.5}>
              {email}
              {emailVerified ? (
                <Tooltip title={t('verified')}>
                  <VerifiedIcon />
                </Tooltip>
              ) : (
                <Tooltip title={t('not_verified')}>
                  <NotVerifiedIcon />
                </Tooltip>
              )}
            </Stack>
          </TableCell>
          <TableCell>
            <RoleSelect
              value={isAdmin ? 'admin' : 'user'}
              renderValue={(value) => t(value)}
              onChange={(e) => onRoleChange(id, e.target.value)}
              disabled={!notMe}
              fullWidth
              hiddenLabel
            />
          </TableCell>
          <TableCell align="right">
            {invitePending && <Chip label={t('invite_pending')} />}
            <IconButton disabled={!notMe} onClick={() => onDelete(row)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </>
      );
    },
    [myId, onRoleChange, onDelete, t]
  );

  return {
    renderRow,
    renderItem,
  };
}
