import createStore from '../utils/create-store';

const IS_LOGGED_IN_ID = 'compodium-admin-is-logged-in';
const DARKMODE_ID = 'compodium-admin-darkmode';
const ORGANISATION_ID = 'compodium-admin-organisation';
const SHOW_DEV_ID = 'compodium-admin-show-dev';

const _UserStore = createStore({
  isLoggedIn: !!localStorage.getItem(IS_LOGGED_IN_ID),
  darkmode: !!localStorage.getItem(DARKMODE_ID),
  loading: true,
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  emailSubscription: false,
  org: localStorage.getItem(ORGANISATION_ID),
  orgObj: {},
  currentGroupId: '',
  locale: 'en',
  orgs: [],
  orgDisplayName: '',
  isAdmin: false,
  permissions: {},
  notifications: [],
  totp: [],
  showDev: localStorage.getItem(SHOW_DEV_ID) === '1',
  orgsPopulated: false,
});

export class UserStore extends _UserStore {
  setIsLoggedIn(value) {
    this.isLoggedIn = value;

    if (value) {
      localStorage.setItem(IS_LOGGED_IN_ID, '1');
    } else {
      localStorage.removeItem(IS_LOGGED_IN_ID);
    }
  }

  setDarkmode(value) {
    this.darkmode = value;
    if (value) {
      localStorage.setItem(DARKMODE_ID, '1');
    } else {
      localStorage.removeItem(DARKMODE_ID);
    }
  }

  setOrganisation(orgName) {
    if (!orgName) {
      this.org = '';
      localStorage.removeItem(ORGANISATION_ID);
      this.orgObj = {};
      this.orgDisplayName = '';
      return {};
    }

    const orgObj = this.orgs.find((x) => x.name === orgName);
    if (!orgObj) {
      this.org = '';
      localStorage.removeItem(ORGANISATION_ID);
      this.orgDisplayName = '';
      return {};
    }

    this.org = orgName;
    localStorage.setItem(ORGANISATION_ID, orgName);
    this.orgObj = this.orgs.find((x) => x.name === orgName);
    this.orgDisplayName = this.orgObj?.displayName || orgName;
    this.currentGroupId = this.orgObj?.id;

    return this.orgObj;
  }

  setShowDev(value) {
    this.showDev = value;
    if (value) {
      localStorage.setItem(SHOW_DEV_ID, '1');
    } else {
      localStorage.removeItem(SHOW_DEV_ID);
    }
  }

  setOrgsPopulated(value) {
    this.orgsPopulated = value;
  }

  setOrganisationObject(value) {
    this.orgObj = value;
  }
}

export default new UserStore();
