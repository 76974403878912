import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Text } from '@compodium/ui-lib/material';
import { Check, Close } from '@compodium/ui-lib/icons';

const msToMinutes = (ms) => Math.round(ms / 1000 / 60);

export default function FeatureList({ features, infinityConstant }) {
  const { t } = useTranslation();

  const createRow = useCallback(
    (key, hasFeature, value) => (
      <Stack key={key} direction="row" gap={0.5}>
        <Text>{hasFeature ? <Check /> : <Close />}</Text>
        <Text>
          {key}
          {value ? ` (${value})` : ''}
        </Text>
      </Stack>
    ),
    []
  );

  const children = [];
  if (
    features.maxMeetingDurationMs &&
    features.maxMeetingDurationMs !== infinityConstant
  ) {
    children.push(
      createRow(
        t('features.maxMeetingDurationMs'),
        true,
        `${msToMinutes(features.maxMeetingDurationMs)} ${t('minutes')}`
      )
    );
  }
  if (features.callRecording) {
    if (
      features.maxCallRecordingDurationMs &&
      features.maxCallRecordingDurationMs !== infinityConstant
    ) {
      children.push(
        createRow(
          t('features.maxCallRecordingDurationMs'),
          true,
          `${msToMinutes(features.maxCallRecordingDurationMs)} ${t('minutes')}`
        )
      );
    } else {
      children.push(
        createRow(t('features.callRecording'), features.callRecording)
      );
    }
  }
  if (features.callTranscription) {
    children.push(
      createRow(t('features.callTranscription'), features.callTranscription)
    );
  }
  if (features.roomLock) {
    children.push(createRow(t('features.roomLock'), features.roomLock));
  }
  /* not yet implemented
  if (features.breakOutRooms) {
    children.push(createRow(t('features.breakOutRooms'), features.breakOutRooms));
  }
  */

  return (
    <Grid container spacing={0.5} p={1}>
      {children.map((child) => (
        <Grid item xs={12} sm={6} key={child.key}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}
