import { TablePagination as MuiPagination } from '@compodium/ui-lib/material';

const noop = () => {};

export default function Pagingation({
  page = 0,
  setPage = noop,
  total = 0,
  pageSize = 10,
  setPageSize = noop,
}) {
  const handlePageChange = (_, v) => setPage(v);
  const handleRowCountChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setPage(0);
  };
  return (
    <MuiPagination
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleRowCountChange}
      count={total}
      sx={{ borderBottomWidth: 0 }}
    />
  );
}
