import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import {
  Admin,
  Analytics,
  // Billing,
  // Dashboard,
  Home,
  JoinWorkspace,
  Login,
  Meeting,
  Meetings,
  Notifications,
  RequestSent,
  SetupWorkspace,
  Subscriptions,
  UserSettings,
  Workspaces,
  PickWorkspace,
  PaymentConfirmation,
} from './views';
import { Configure, Insights } from './views/Embedded';
import Logout from './components/Logout';
import Restricted from './components/Restricted';
import Snackbar from './components/SnackBar';
import ThemeProvider from './components/ThemeProvider';
import ErrorBoundary from './components/layout/ErrorBoundary';
import SetOrganisationWrapper from './components/SetOrganisationWrapper';
import useUser from './hooks/useUser';
import { getMe, getNotifications } from './actions/UserActions';

function App() {
  const { darkmode, org, isLoggedIn } = useUser();

  useEffect(() => {
    if (isLoggedIn) {
      getMe();
      getNotifications();
      document.title = 'Compodium Admin';
    }
  }, [org, isLoggedIn]);

  return (
    <ThemeProvider darkmode={darkmode}>
      <ErrorBoundary>
        {isLoggedIn ? (
          <Router>
            <Routes>
              <Route
                index
                element={<SetOrganisationWrapper ChildComponent={Home} />}
              />
              <Route
                path="admin"
                element={<Restricted RestrictedComponent={Admin} isAdmin />}
              />
              <Route
                path="analytics"
                element={
                  <SetOrganisationWrapper
                    ChildComponent={Analytics}
                    requireOrgs
                  />
                }
              />
              {/* <Route path="billing" element={<Billing />} /> */}
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route path="meetings">
                <Route
                  index
                  element={
                    <SetOrganisationWrapper
                      ChildComponent={Meetings}
                      requireOrgs
                    />
                  }
                />
                <Route
                  path=":id"
                  element={
                    <SetOrganisationWrapper
                      ChildComponent={Meeting}
                      requireOrgs
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <SetOrganisationWrapper
                      ChildComponent={Navigate}
                      requireOrgs
                      to="/meetings"
                    />
                  }
                />
              </Route>
              <Route path="notifications" element={<Notifications />} />
              <Route
                path="subscriptions"
                element={
                  <SetOrganisationWrapper
                    ChildComponent={Subscriptions}
                    requireOrgs
                  />
                }
              />
              <Route path="embedded">
                <Route
                  index
                  element={
                    <SetOrganisationWrapper
                      ChildComponent={Navigate}
                      requireOrgs
                      to="configure"
                    />
                  }
                />
                <Route
                  path="configure"
                  element={
                    <SetOrganisationWrapper
                      ChildComponent={Restricted}
                      permission="read.integrations"
                      RestrictedComponent={Configure}
                    />
                  }
                />
                <Route
                  path="insights"
                  element={
                    <SetOrganisationWrapper
                      ChildComponent={Restricted}
                      permission="read.integrations"
                      RestrictedComponent={Insights}
                    />
                  }
                />
              </Route>
              <Route
                path="success/:type/:sessionId"
                element={<PaymentConfirmation />}
              />
              <Route path="settings" element={<UserSettings />} />
              <Route path="workspaces">
                <Route index element={<Workspaces />} />
                <Route path="setup" element={<SetupWorkspace />} />
                <Route path="join" element={<JoinWorkspace />} />
                <Route path="request-sent" element={<RequestSent />} />
                <Route path="pick" element={<PickWorkspace />} />
                <Route path="*" element={<Navigate to="/workspaces" />} />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<Logout />} />
              <Route path="*" element={<Navigate to="/workspaces" />} />
            </Routes>
          </Router>
        ) : (
          <Router>
            <Login />
          </Router>
        )}
        <Snackbar />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
