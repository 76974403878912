import { useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Stack,
  StepContent,
  Stepper,
  Step,
  StepLabel,
  Text,
} from '@compodium/ui-lib/material';
import BackIcon from '@compodium/ui-lib/icons/ChevronLeft';
import { Step1, Wrapper, SetupComplete } from '../components/Workspaces/Setup';
import { addSnack } from '../actions/SnackActions';
import { orgNameAvailable, createOrg } from '../actions/WorkspaceActions';
import { getOrgs, setOrg } from '../actions/UserActions';
import logger from '../utils/logger';

export default function SetupWorkspace() {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const steps = [t('setup_workspace_step_1')];
  const isOptional = (idx) => idx === 1;
  const [name, setName] = useState('');
  const [step1Error, setStep1Error] = useState('');

  const on1 = async (selectedName) => {
    try {
      await orgNameAvailable(selectedName);
    } catch (e) {
      if (e instanceof AxiosError && e?.response?.status === 409) {
        setStep1Error(t('name_not_available'));
        return;
      }
    }

    setName(selectedName);

    try {
      /**
       We need to use selectedName here since it is not guaranteed that the
       state will be updated.
       */
      await createOrg(selectedName);
      setStep(1);
    } catch (e) {
      const { message, detail } = e?.response?.data || {};
      setStep1Error(detail || message || 'Something went wrong');
    }
  };

  const onDone = async () => {
    try {
      await getOrgs();
      setOrg(name);
      nav('/subscriptions');
    } catch (e) {
      logger.error(e);
      const err =
        e?.response?.data?.detail ||
        e?.response?.data?.message ||
        e?.message ||
        'Something went wrong';
      addSnack(`Error: ${err}`);
    }
  };

  return (
    <Wrapper>
      <Stack gap={2} alignItems="start">
        <Button onClick={() => nav('/workspaces')} startIcon={<BackIcon />}>
          {t('cancel_setup')}
        </Button>
        <Stack gap={2.5}>
          <Stepper activeStep={step} orientation="vertical">
            {steps.map((label, idx) => (
              <Step key={label}>
                <StepLabel
                  optional={
                    isOptional(idx) && (
                      <Text variant="caption">{t('optional')}</Text>
                    )
                  }
                >
                  {label}
                </StepLabel>
                <StepContent>
                  {step === 0 && (
                    <Step1 initValue={name} submit={on1} error={step1Error} />
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {step === steps.length && <SetupComplete onDone={onDone} />}
        </Stack>
      </Stack>
    </Wrapper>
  );
}
