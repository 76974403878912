import createStore from '../utils/create-store';

const _SnackStore = createStore({
  snacks: [],
});

export class SnackStore extends _SnackStore {
  constructor() {
    super();
    setInterval(() => {
      this.snacks$.next(
        this.snacks$.value.filter((x) => Date.now() - x.created < 6000)
      );
    }, 1000);
  }

  addSnack(msg, level = 'warn') {
    const o = {
      msg,
      level,
      created: Date.now(),
    };
    this.snacks = [o];
  }

  clear() {
    this.snacks = [];
  }
}

export default new SnackStore();
