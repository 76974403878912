import { useCallback, useMemo, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Unstable_Grid2 as Grid,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import { useTheme } from '@compodium/ui-lib/material/styles';
import { getTranscriptDl } from '../../actions/MeetingActions';

export default function MeetingTranscript({ meeting, ts, sx = {} }) {
  const [transcript, setTranscript] = useState([]);
  const theme = useTheme();
  const { id, transcribeExists, peersData = {} } = meeting;
  const src = getTranscriptDl(id);
  useEffect(() => {
    if (!transcribeExists) return () => {};

    axios.get(src).then((res) => {
      setTranscript(res.data?.texts || []);
    });

    return () => {
      setTranscript([]);
    };
  }, [id]);

  const formattedTranscript = useMemo(
    () =>
      transcript.reduce((acc, x) => {
        const { speaker, text, start, end } = x;
        const line = { text, start, end };
        const prevSpeaker = acc.length > 0 ? acc[acc.length - 1] : null;
        if (prevSpeaker === speaker) {
          acc[acc.length - 1].lines.push(line);
          acc[acc.length - 1].end = end;
        } else {
          acc.push({
            speaker,
            start,
            lines: [line],
          });
        }
        return acc;
      }, []),
    [transcript]
  );

  const getBgColor = (time, start, end) =>
    time && time >= start && time <= end ? theme.palette.action.focus : 'unset';

  const renderTranscript = useCallback(
    (row) => (
      <Grid container key={row.start}>
        <Grid item xs={6} sm={2}>
          <Text variant="body1" color="text.secondary">
            {peersData?.[row.speaker]?.name || row.speaker}
          </Text>
        </Grid>
        <Grid item xs={6} sm={10}>
          {(row.lines || []).map((line) => (
            <Text
              key={line.start}
              variant="subtitle1"
              component="span"
              sx={{
                p: 0.25,
                backgroundColor: getBgColor(ts, line.start, line.end),
              }}
            >
              {line.text}
            </Text>
          ))}
        </Grid>
      </Grid>
    ),
    [ts, peersData]
  );

  if (!transcribeExists) {
    return <Text>Transcript not ready...</Text>;
  }

  return <Stack sx={sx}>{formattedTranscript.map(renderTranscript)}</Stack>;
}
