import createStore from '../utils/create-store';

export const WorkspaceStore = createStore({
  members: [],
  totalMembers: 0,
  requests: [],
  totalRequests: 0,
});

export default new WorkspaceStore();
