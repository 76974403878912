import { get, del, objectToQuery, cancelCalls, addCtCheck } from '../utils/api';
import meetStore from '../stores/MeetingStore';
import userStore from '../stores/UserStore';

// eslint-disable-next-line
export async function getMeetings({ offset, limit }) {
  const o = userStore.org;
  if (!o) return Promise.reject();
  const q = objectToQuery({ offset, limit });
  cancelCalls('getMeetings');
  const cancelToken = addCtCheck('getMeetings');
  meetStore.loading = true;
  return get(`/orgs/${o}/meetings${q?.length ? `?${q}` : ''}`, {}, cancelToken)
    .then((res) => {
      if (!res?.data) return;
      const { meetings = [], total = 0 } = res.data || {};
      meetStore.meetings = meetings;
      meetStore.total = total;
      meetStore.loading = false;
    })
    .catch(() => {
      meetStore.loading = false;
    });
}

export async function getMeeting(id) {
  const o = userStore.org;
  if (!o) return Promise.reject();
  cancelCalls('getMeeting');
  const cancelToken = addCtCheck('getMeeting');
  meetStore.loading = true;

  return get(`/orgs/${o}/meetings/${id}`, {}, cancelToken)
    .then((res) => {
      if (!res?.data) return;
      const { meeting } = res.data || {};
      meetStore.meeting = meeting;
      meetStore.loading = false;
    })
    .catch(() => {
      meetStore.loading = false;
    });
}

export async function deleteMeeting(id) {
  const o = userStore.org;
  if (!o) return Promise.reject();
  return del(`/orgs/${o}/meetings/${id}`);
}

export function getRecDl(id) {
  const o = userStore.org;
  if (!o) return Promise.reject();
  return `/api/orgs/${o}/meetings/dl/${id}.mp4`;
}
export function getTranscriptDl(id, ext = 'json') {
  if (!['json', 'srt', 'txt'].includes(ext)) return '';
  const o = userStore.org;
  if (!o) return '';
  return `/api/orgs/${o}/meetings/dl-transcript/${id}.${ext}`;
}

export function clearMeeting() {
  meetStore.meeting = {};
  meetStore.loading = false;
}

export function clearMeetings() {
  meetStore.meetings = [];
  meetStore.loading = true;
}
