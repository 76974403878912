import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import {
  ArrowBack as BackIcon,
  Close as CrossIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from '@compodium/ui-lib/icons';
import ThemeProvider from '../ThemeProvider';
import DlMeeting from './DlMeeting';
import DlTranscript from './DlTranscript';
import Logo from '../Logo';
import UserCard from '../UserCard';
import useIsMobile from '../../hooks/useIsMobile';
import useUser from '../../hooks/useUser';

function DeleteButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="error"
      startIcon={<DeleteIcon />}
      onClick={onClick}
    >
      {t('delete')}
    </Button>
  );
}

function DeleteListItem({ onClick }) {
  const { t } = useTranslation();

  return (
    <ListItem disableGutters>
      <ListItemButton onClick={onClick}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary={t('delete')} />
      </ListItemButton>
    </ListItem>
  );
}

function Actions({ mobile = false, meeting, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const disabled = !meeting;

  return (
    <ThemeProvider darkmode noBaseline>
      {mobile ? (
        <>
          <IconButton onClick={openMenu} disabled={disabled}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            keepMounted
          >
            <DeleteListItem onClick={onDelete} />
            <DlMeeting type="list-item" meeting={meeting} />
            <DlTranscript type="list-item" meeting={meeting} />
          </Menu>
        </>
      ) : (
        <Stack direction="row" gap={1}>
          <DlMeeting meeting={meeting} disabled={disabled} />
          <DlTranscript meeting={meeting} disabled={disabled} />
          <DeleteButton onClick={onDelete} disabled={disabled} />
        </Stack>
      )}
    </ThemeProvider>
  );
}

export default function Header({ meeting, meetingId, onDelete }) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const isMobile = useIsMobile();
  const { darkmode } = useUser();

  const onBack = () => nav(-1);

  const backgroundColor = darkmode ? 'background.default' : 'blackish.main';

  return (
    <ThemeProvider darkmode noBaseline>
      {isMobile ? (
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          p={0.5}
          sx={{ backgroundColor }}
        >
          <IconButton onClick={onBack}>
            <CrossIcon />
          </IconButton>
          <Text
            variant="subtitle1"
            color="text.primary"
            sx={{ textOverflow: 'ellipsis' }}
          >
            {meetingId}
          </Text>
          <Actions mobile meeting={meeting} onDelete={onDelete} />
        </Stack>
      ) : (
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor,
            borderColor: 'divider',
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: 1,
            '& > *': { px: 1, py: 0.5 },
          }}
        >
          <Stack
            direction="row"
            gap={1.5}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button startIcon={<BackIcon />} onClick={onBack}>
              {t('back')}
            </Button>
            <Logo darkmode variant="short" width={38} />
            <Text variant="subtitle1">{meetingId}</Text>
          </Stack>

          <Stack direction="row" gap={1.5} alignItems="center">
            <Actions meeting={meeting} onDelete={onDelete} />
            <UserCard variant="short" />
          </Stack>
        </Stack>
      )}
    </ThemeProvider>
  );
}
