import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  Card,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import { useTheme } from '@compodium/ui-lib/material/styles';
import { List, Pagination, Table, TablePagination } from '../components/list';
import CamIcon from '../components/icons/Cam';
import ViewMeetingDialog from '../components/Meetings/ViewMeetingDialog';
import useMeetingList from '../components/Meetings/useMeetingList';
import DeleteDialog from '../components/Modals/DeleteDialog';
import Wrapper from '../components/layout/Wrapper';
import useIsMobile from '../hooks/useIsMobile';
import useMeetings from '../hooks/useMeeting';
import useQuery from '../hooks/useQuery';
import useOrgChanged from '../hooks/useOrgChanged';
import useUser from '../hooks/useUser';
import {
  getMeetings,
  deleteMeeting,
  clearMeetings,
} from '../actions/MeetingActions';

function Title() {
  const { t } = useTranslation();

  return (
    <Stack gap={0.5}>
      <Text variant="h4">{t('meetings_title')}</Text>
      <Text variant="body1" color="text.secondary">
        {t('meetings_title_desc')}
      </Text>
    </Stack>
  );
}

function Empty() {
  const { t } = useTranslation();
  const { darkmode } = useUser();

  return (
    <Stack
      gap={0.5}
      sx={{
        borderRadius: '8px',
        p: 2,
        backgroundColor: darkmode ? 'grey.900' : 'grey.100',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <CamIcon gray darkmode={darkmode} />
      <Text variant="h5">{t('meetings_empty_title')}</Text>
      <Text variant="body1">{t('meetings_empty_desc')}</Text>
    </Stack>
  );
}

function NotEmpty({
  setToDelete,
  onClick,
  onMobileClick,
  meetings,
  page,
  setPage,
  pageSize,
  setPageSize,
  total,
  loading,
}) {
  const theme = useTheme();
  const isMobile = useIsMobile(theme.breakpoints.down('md'));
  const { headers, renderRow, renderMobileItem } = useMeetingList({
    onDelete: setToDelete,
    onMobileClick,
  });

  if (isMobile) {
    return (
      <>
        <List
          data={meetings}
          renderItem={renderMobileItem}
          dividerSx={{ mx: -1 }}
        />
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total={total}
          sx={{ justifyContent: 'center' }}
        />
      </>
    );
  }

  return (
    <Card sx={{ p: 1, width: '100%', borderRadius: 2 }}>
      <Table
        data={meetings}
        headers={headers}
        renderRow={renderRow}
        loading={loading}
        onClick={onClick}
        rowSx={{ cursor: 'pointer' }}
        footer={
          <TablePagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={total}
          />
        }
      />
    </Card>
  );
}

export default function Meetings() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { meetings, total, loading } = useMeetings();
  const orgChanged = useOrgChanged();
  const [toDelete, setToDelete] = useState(null);
  const [view, setView] = useState(null);

  const onClick = (row) => {
    nav(row.id);
  };
  const onMobileClick = (row) => setView(row);

  const { page, offset, setPage, pageSize, setPageSize } = useQuery({
    pageSize: 50,
    total,
  });

  const refetch = () => {
    getMeetings({ offset, limit: pageSize });
  };

  const cancelDelete = () => setToDelete(null);

  useEffect(
    () => () => {
      clearMeetings();
    },
    []
  );
  useEffect(() => {
    refetch();
  }, [pageSize, page, orgChanged]);
  useEffect(() => {
    setPage(0);
  }, [orgChanged]);

  const onDelete = () =>
    deleteMeeting(toDelete.id)
      .then(() => setToDelete(null))
      .then(() => refetch('', meetings.length - 1));

  /* eslint-disable no-nested-ternary */
  return (
    <Wrapper>
      <Stack gap={1}>
        <Title />
        {loading ? (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : meetings.length === 0 ? (
          <Empty />
        ) : (
          <NotEmpty
            {...{
              onClick,
              setToDelete,
              onMobileClick,
              meetings,
              page,
              setPage,
              pageSize,
              setPageSize,
              total,
              loading,
            }}
          />
        )}

        <ViewMeetingDialog
          open={Boolean(view)}
          onClose={() => setView(null)}
          meeting={view}
          onDelete={() => setToDelete(view)}
        />
        <DeleteDialog
          open={Boolean(toDelete)}
          onConfirm={onDelete}
          onCancel={cancelDelete}
        >
          {t(`confirm_delete {{id}}`, { id: toDelete?.id })}
        </DeleteDialog>
      </Stack>
    </Wrapper>
  );
}
