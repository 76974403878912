import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@compodium/ui-lib/material';
import { EmbeddedStripeTable } from '../StripeTable';

export default function EmbeddedPricingTable({ clientReferenceId }) {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Stack gap={0.5}>
        <Text variant="h5" color="primary.main">
          {t('subscriptions_embedded_title')}
        </Text>
        <Text variant="subtitle2" color="text.secondary">
          {t('subscriptions_embedded_desc')}
        </Text>
      </Stack>
      <EmbeddedStripeTable clientReferenceId={clientReferenceId} />
    </Stack>
  );
}
