import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Stack, Text } from '@compodium/ui-lib/material';
import { useTheme } from '@compodium/ui-lib/material/styles';
import {
  Add as AddIcon,
  ArrowForward as GoToIcon,
} from '@compodium/ui-lib/icons';
import Wrapper from '../components/Workspaces/Wrapper';
import OrgSelect from '../components/OrgSelect';
import useUser from '../hooks/useUser';
import { getOrgs } from '../actions/UserActions';

const noop = () => {};

function Option({ title, subtitle, icon, children, onClick = noop }) {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '1rem',
        border: '1px solid lightgray',
        p: 1,
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.action.hover,
        },
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {!!icon && icon}
        <Stack flexGrow={1}>
          {typeof title === 'string' ? (
            <Text fontSize={16} fontWeight={600}>
              {title}
            </Text>
          ) : (
            title
          )}
          {!!subtitle && (
            <Text fontSize={12} fontWeight={400} color="text.secondary">
              {subtitle}
            </Text>
          )}
          {!!children && children}
        </Stack>
      </Stack>
    </Paper>
  );
}

function OrgSelectOption({ orgs }) {
  const { t } = useTranslation();
  const goTo = useNavigate();
  const baseOrgs = orgs.filter((x) => x?.type === 'group');
  if (baseOrgs.length < 1)
    return (
      <Option
        title={t('continue_without_workspace')}
        subtitle={t('continue_without_workspace_desc')}
        onClick={() => goTo('/')}
      />
    );
  return (
    <Option
      title={
        <Stack direction="row" justifyContent="space-between">
          {t('continue_with_existing_workspace')}
          <GoToIcon sx={{ color: 'text.secondary' }} />
        </Stack>
      }
      onClick={() => goTo('/')}
    >
      <Stack pt={1}>
        <OrgSelect />
      </Stack>
    </Option>
  );
}

function AddBox() {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: '50%',
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
    >
      <AddIcon />
    </Box>
  );
}

function Content() {
  const goTo = useNavigate();
  const { t } = useTranslation();
  const { orgs } = useUser();

  return (
    <Stack gap={1} alignItems="strech" justifyContent="center">
      <Stack gap={0.5}>
        <Text variant="h4">{t('choose_workspace')}</Text>
        <Text fontSize={15} fontWeight={500} color="text.secondary">
          {t('choose_workspace_desc')}
        </Text>
      </Stack>
      <Stack gap={1}>
        <Option
          title={t('join_workspace')}
          subtitle={t('join_workspace_desc')}
          onClick={() => goTo('join')}
        />
        <Option
          title={t('create_workspace')}
          subtitle={t('create_workspace_desc')}
          onClick={() => goTo('setup')}
          icon={<AddBox />}
        />
        <OrgSelectOption orgs={orgs} />
      </Stack>
    </Stack>
  );
}

export default function Workspaces() {
  useEffect(() => {
    getOrgs();
  }, []);

  return (
    <Wrapper>
      <Content />
    </Wrapper>
  );
}
