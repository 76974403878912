import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Text } from '@compodium/ui-lib/material';
import FeatureList from './FeatureList';
import PriceSummary from './PriceSummary';
import SubUsage from './SubUsage';

function SubDetails({ sub, infinityConstant, renewalDate, title }) {
  return (
    <Stack gap={1}>
      <Text variant="h6">{title}</Text>
      <FeatureList
        features={sub.attributes}
        infinityConstant={infinityConstant}
      />
      <SubUsage
        sub={sub}
        renewalDate={renewalDate}
        infinityConstant={infinityConstant}
      />
    </Stack>
  );
}

export default function SubscriptionsDetails({ sub, isAdmin }) {
  const { t } = useTranslation();
  const { embedded, meet, infinityConstant } = sub || {};
  const hasMeet = !!meet;
  const hasEmbedded = !!embedded;
  const renewalDate = DateTime.now()
    .plus({ month: 1 })
    .startOf('month')
    .toFormat('D');

  return (
    <Stack gap={1.5}>
      <Stack gap={0.75}>
        <Text variant="h5" color="primary.main">
          {t('your_plan')}
        </Text>
        <Card sx={{ px: 1.25, py: 2 }}>
          {hasEmbedded && (
            <SubDetails
              title={
                embedded?.product?.name ? embedded.product.name : t('embedded')
              }
              sub={embedded}
              infinityConstant={infinityConstant}
              renewalDate={renewalDate}
            />
          )}

          {hasMeet && (
            <SubDetails
              title={meet?.product?.name ? meet.product.name : t('meet')}
              sub={meet}
              infinityConstant={infinityConstant}
              renewalDate={renewalDate}
            />
          )}
        </Card>
      </Stack>

      <PriceSummary sub={sub} renewalDate={renewalDate} isAdmin={isAdmin} />
    </Stack>
  );
}
