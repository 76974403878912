import { useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Stack, Text } from '@compodium/ui-lib/material';
import { KeyValue, MinutesUsedGraph, YourUsage } from '../../Analytics';
import DateRangeDialog from '../../Modals/DateRangeDialog';
import { useDateRange } from '../../../contexts/DateRangeContext';
import useUser from '../../../hooks/useUser';
import { exportCsv } from '../../../actions/AnalyticsActions';

export default function Content({ analytics }) {
  const msToM = 1 / 1000 / 60;
  const { t } = useTranslation();
  const { darkmode } = useUser();
  const [showDateRange, setShowDateRange] = useState(false);
  const { from, to, setFrom, setTo } = useDateRange();

  const openDateRange = () => setShowDateRange(true);
  const closeDateRange = () => setShowDateRange(false);
  const dateToInput = (d) => DateTime.fromJSDate(d).toISO().slice(0, 16);
  const prettifyDate = (d) => DateTime.fromJSDate(d).toFormat('D T');

  const submitDateRange = (fromDate, toDate) => {
    setFrom(new Date(fromDate));
    setTo(new Date(toDate));
    closeDateRange();
  };

  const reduced = analytics.reduce(
    (acc, cur) => {
      acc.meetingDuration += cur.meetingDuration;
      acc.callRecordingDuration += cur.callRecordingDuration;
      acc.participantCount += cur.numPeers || 1;
      return acc;
    },
    {
      meetingDuration: 0,
      callRecordingDuration: 0,
      participantCount: 0,
    }
  );
  const meetingMinutes = Math.ceil(reduced.meetingDuration * msToM);
  const recordingMinutes = Math.ceil(reduced.callRecordingDuration * msToM);
  const numberOfMeetings = analytics.length;
  const participants = reduced.participantCount;

  const onExportCsv = () => {
    const date = t('date');
    const duration = t('duration');
    const nPeers = t('number_of_peers');
    const recDur = t('recording_duration');
    const data = analytics.map((d) => ({
      [date]: DateTime.fromISO(d.startedAt).toFormat('D TT'),
      [duration]: d.meetingDuration * msToM,
      [nPeers]: d.numPeers || 1,
      [recDur]: (d.callRecordingDuration || 0) * msToM,
    }));
    exportCsv(data);
  };

  return (
    <Stack
      gap={1}
      sx={{
        backgroundColor: darkmode ? 'grey.900' : 'grey.200',
        borderRadius: 1,
        px: 1,
        py: 1.5,
      }}
    >
      <YourUsage analytics={analytics} />

      <Divider />

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <KeyValue title={t('meeting_minutes')} value={meetingMinutes} />
          <KeyValue title={t('recording_minutes')} value={recordingMinutes} />
        </Stack>

        <Stack direction="row" gap={1}>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={openDateRange}
            >
              {t('time_period')}
            </Button>
            <DateRangeDialog
              open={showDateRange}
              onClose={closeDateRange}
              from={dateToInput(from)}
              to={dateToInput(to)}
              onSubmit={submitDateRange}
            />
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={onExportCsv}>
              {t('export_csv')}
            </Button>
          </div>
        </Stack>
      </Stack>

      <Text color="text.secondary">{`${prettifyDate(from)} - ${prettifyDate(
        to
      )}`}</Text>

      <MinutesUsedGraph analytics={analytics} />

      <Stack direction="row" gap={2}>
        <KeyValue title={t('number_of_meetings')} value={numberOfMeetings} />
        <KeyValue title={t('participants')} value={participants} />
      </Stack>
    </Stack>
  );
}
