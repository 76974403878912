import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@compodium/ui-lib/material';
import DownloadIcon from '@compodium/ui-lib/icons/FileDownload';
import { getTranscriptDl } from '../../actions/MeetingActions';

function DlMenu({ id, anchor, onClose }) {
  const { t } = useTranslation();
  const txtDl = getTranscriptDl(id, 'txt');
  const srtDl = getTranscriptDl(id, 'srt');
  return (
    <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
      <MenuItem component="a" href={txtDl} download>
        {t('textfile')} (.txt)
      </MenuItem>
      <MenuItem component="a" href={srtDl} download>
        {t('subtitles')} (.srt)
      </MenuItem>
    </Menu>
  );
}

export default function DlTranscript({
  type = 'button',
  meeting,
  disabled = false,
}) {
  const { t } = useTranslation();
  // const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const { id, transcribeExists } = meeting;
  // const txtName = `${id}.txt`;
  // const txtDl = getTranscriptDl(id, 'txt');
  // const srtName = `${id}.srt`;
  // const srtDl = getTranscriptDl(id, 'srt');

  const onClick = (e) => {
    setAnchor(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };

  if (type === 'list-item') {
    return (
      <>
        <ListItem disableGutters>
          <ListItemButton
            onClick={onClick}
            disabled={disabled || !transcribeExists}
          >
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText>{t('transcript')}</ListItemText>
          </ListItemButton>
        </ListItem>
        <DlMenu anchor={anchor} onClose={closeMenu} id={id} />
      </>
    );
  }

  return (
    <>
      <Button
        startIcon={<DownloadIcon />}
        onClick={onClick}
        disabled={disabled || !transcribeExists}
      >
        {t('transcript')}
        {transcribeExists ? '' : ` (${t('not_ready')})`}
      </Button>
      <DlMenu anchor={anchor} onClose={closeMenu} id={id} />
    </>
  );
}
