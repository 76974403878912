import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { LinearProgress, Stack, Text } from '@compodium/ui-lib/material';

export default function YourUsage({
  analytics,
  maxMeetingDurationMs,
  infinityConstant,
}) {
  const msToM = 1 / 1000 / 60;
  const { t } = useTranslation();
  const used = Math.ceil(
    analytics.reduce(
      (acc, cur) => acc + cur.meetingDuration, // * (cur?.numPeers || 1),
      0
    ) * msToM
  );
  const renewalDate = DateTime.now()
    .startOf('month')
    .plus({ month: 1 })
    .toFormat('D');

  // TODO: determine using subscription
  const total = maxMeetingDurationMs * msToM;

  return (
    <Stack gap={1}>
      <Text variant="h6">{t('your_usage')}</Text>
      <LinearProgress
        variant="determinate"
        value={(used / total) * 100}
        sx={{ width: 200 }}
      />
      <Text color="text.secondary">
        {maxMeetingDurationMs === infinityConstant
          ? t('minutes_used {{used}} {{renewalDate}}', {
              used,
              renewalDate,
            })
          : t('minutes_left {{part}} {{total}} {{renewalDate}}', {
              part: total - used,
              total,
              renewalDate,
            })}
      </Text>
    </Stack>
  );
}
