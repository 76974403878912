import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, MenuItem } from '@compodium/ui-lib/material';
import EditIcon from '@compodium/ui-lib/icons/Edit';
import EditWorkspaceDialog from './EditWorkspaceDialog';

export default function EditWorkspaceMenuItem() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const doOpen = (e) => setAnchorEl(e.currentTarget);
  const doClose = () => setAnchorEl(null);

  return (
    <>
      <MenuItem onClick={doOpen}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        {t('edit')}
      </MenuItem>
      <EditWorkspaceDialog open={!!anchorEl} onClose={doClose} />
    </>
  );
}
