/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import {
  Container,
  SwipeableDrawer,
  Stack,
  Unstable_Grid2 as Grid,
} from '@compodium/ui-lib/material';
import { useTheme } from '@compodium/ui-lib/material/styles';
import ErrorBoundary from './ErrorBoundary';
import DefaultHeader from './Header';
import Navigation from './Navigation';
import useIsMobile from '../../hooks/useIsMobile';

export default function Wrapper({
  header,
  nav = <Navigation />,
  hideNav = false,
  hideHeader = false,
  maxWidth = 'lg',
  childSx = {},
  children,
}) {
  const theme = useTheme();
  const isMobile = useIsMobile(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  if (!['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(maxWidth)) {
    throw new Error(`Invalid maxWidth: ${maxWidth}`);
  }

  const content = (
    <ErrorBoundary>
      <Container
        maxWidth={maxWidth}
        sx={{ pb: 1, pt: { xs: 1, sm: 3 }, px: { xs: 1, sm: 2 }, ...childSx }}
      >
        {children}
      </Container>
    </ErrorBoundary>
  );

  return (
    <Stack height="100vh">
      {!hideHeader &&
        ((header && header) ||
          (isMobile && <DefaultHeader isMobile onMenuClick={openDrawer} />))}

      {hideNav ? (
        content
      ) : (
        <>
          <Grid
            container
            flexGrow={2}
            sx={{ flexWrap: 'nowrap', overflow: 'hidden' }}
          >
            <Grid
              md={4}
              lg={3}
              display={{ xs: 'none', md: 'flex' }}
              minWidth="256px"
              maxWidth="350px"
              width="100%"
              height="100%"
            >
              {nav}
            </Grid>
            <Grid
              id="content-container"
              xs={12}
              md={8}
              lg={9}
              sx={{ overflowY: 'auto' }}
            >
              {content}
            </Grid>
          </Grid>
          <SwipeableDrawer
            anchor="left"
            open={open}
            onOpen={openDrawer}
            onClose={closeDrawer}
            sx={{ display: { xs: 'inherit', md: 'none' } }}
          >
            {nav}
          </SwipeableDrawer>
        </>
      )}
    </Stack>
  );
}
