import createStore from '../utils/create-store';

export const AnalyticsStore = createStore({
  meetAnalytics: {
    duration: [],
    numPeers: [],
    recordingDuration: [],
  },
  embeddedAnalytics: {
    duration: [],
    numPeers: [],
    recordingDuration: [],
  },
});

export default new AnalyticsStore();
