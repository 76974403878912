/* eslint-disable no-console */

export default {
  info: (...args) =>
    window.DEBUG || process.env.NODE_ENV === 'development'
      ? console.log(...args)
      : null,
  warn: (...args) =>
    window.DEBUG || process.env.NODE_ENV === 'development'
      ? console.warn(...args)
      : null,
  error: (...args) =>
    window.DEBUG || process.env.NODE_ENV === 'development'
      ? console.error(...args)
      : null,
};
