import { useTranslation } from 'react-i18next';
import { Grid, Text } from '@compodium/ui-lib/material';
import Logo from './Logo';
import useUser from '../hooks/useUser';
import styles from './Loading.module.css';

function Loading() {
  const { t } = useTranslation();
  const { darkmode } = useUser();
  return (
    <Grid
      container
      className={styles.fullHeightContainer}
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Logo darkmode={darkmode} height="4rem" />
      </Grid>
      <Grid item>
        <Text variant="h4">{t('loading_application')}...</Text>
      </Grid>
    </Grid>
  );
}

export default Loading;
