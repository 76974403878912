import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Text,
  Tooltip,
} from '@compodium/ui-lib/material';
import {
  DarkMode as DarkIcon,
  ExpandMore as OpenIcon,
  Language as LangIcon,
  LightMode as LightIcon,
  Logout as LogoutIcon,
  Settings as CogIcon,
  Payments as SubIcon,
} from '@compodium/ui-lib/icons';
import Darkmode from './Darkmode';
import LangSelect from './LangSelect';
import useUser from '../hooks/useUser';
import { logout } from '../actions/UserActions';

// function OutlinedAvatar({ children, color = 'primary' }) {
//   const theme = useTheme();
//   return (
//     <Avatar sx={{ border: `1px solid ${theme.palette[color].main}` }}>
//       {children}
//     </Avatar>
//   );
// }

export default function UserCard({ variant = 'long', sx = {} }) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { orgObj, darkmode, firstName, lastName, email } = useUser();
  const name = `${firstName} ${lastName}`;

  const open = () => setAnchorEl(ref.current);

  const onLogout = () => {
    logout().then(() => {
      window.reload();
    });
  };

  if (!['long', 'medium', 'short'].includes(variant)) {
    throw new Error(`Invalid variant: ${variant}`);
  }

  const avatarInitials = `${firstName?.length ? firstName.charAt(0) : ''}${
    lastName?.length ? lastName.charAt(0) : ''
  }`;

  return (
    <>
      <Stack
        ref={ref}
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        onClick={open}
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: { xs: 'none', sm: 'action.hover' } },
          ...sx,
        }}
      >
        {variant === 'long' && (
          <>
            <Avatar sx={{ color: 'primary' }}>{avatarInitials}</Avatar>
            <Tooltip
              placement="right"
              title={
                <Stack>
                  <Text>{name}</Text>
                  <Text>{email}</Text>
                </Stack>
              }
            >
              <Stack
                sx={{
                  width: '100%',
                  maxWidth: 150,
                  '& *': { overflow: 'hidden', textOverflow: 'ellipsis' },
                }}
              >
                <Text>{name}</Text>
                <Text color="text.secondary">{email}</Text>
              </Stack>
            </Tooltip>
            <OpenIcon />
          </>
        )}
        {variant === 'medium' && (
          <>
            <Avatar sx={{ color: 'primary' }}>{avatarInitials}</Avatar>
            <OpenIcon />
          </>
        )}
        {variant === 'short' && (
          <Avatar sx={{ color: 'primary' }}>{avatarInitials}</Avatar>
        )}
      </Stack>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        PaperProps={{ sx: { minWidth: 200 } }}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              {darkmode ? <DarkIcon /> : <LightIcon />}
            </ListItemIcon>
            <Darkmode />
          </ListItem>

          <Divider />

          <ListItem>
            <ListItemIcon>
              <LangIcon />
            </ListItemIcon>
            <ListItemText>
              <LangSelect />
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => nav('/settings')}>
              <ListItemIcon>
                <CogIcon />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </ListItemButton>
          </ListItem>
          {!orgObj?.subscription && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => nav('/subscriptions')}>
                <ListItemIcon>
                  <SubIcon />
                </ListItemIcon>
                <ListItemText primary={t('go_pro')} />
              </ListItemButton>
            </ListItem>
          )}

          <Divider />

          <ListItem disablePadding>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('sign_out')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
    </>
  );
}
