import { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import DeleteDialog from '../components/Modals/DeleteDialog';
import {
  Header,
  MeetingVideo,
  MeetingVideoFooter,
  MeetingTranscript,
} from '../components/Meeting';
import Wrapper from '../components/layout/Wrapper';
import useMeetings from '../hooks/useMeeting';
import useOrgChanged from '../hooks/useOrgChanged';
import {
  getMeeting,
  deleteMeeting,
  clearMeeting,
} from '../actions/MeetingActions';

function VideoAndTranscript({ meeting }) {
  const videoRef = useRef();
  const [ts, setTs] = useState(0);
  const { transcribe, playback } = meeting || {};

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.ontimeupdate = (e) => {
      setTs(e.target?.currentTime);
    };
  }, [meeting, videoRef.current]);

  return (
    <Stack gap={1} height="100%" sx={{ overflowY: 'auto' }}>
      <div>
        {playback && (
          <>
            <MeetingVideo ref={videoRef} meeting={meeting || {}} />
            <MeetingVideoFooter meeting={meeting || {}} />
          </>
        )}
      </div>
      {playback && transcribe && <Divider />}
      {transcribe && (
        <MeetingTranscript
          meeting={meeting || {}}
          ts={ts}
          sx={{
            minHeight: 100,
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        />
      )}
    </Stack>
  );
}

export default function Meeting() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { loading, meeting } = useMeetings();
  const [toDelete, setToDelete] = useState(null);
  const orgChanged = useOrgChanged();
  const nav = useNavigate();

  const refetch = () => getMeeting(id);

  useEffect(() => {
    if (orgChanged) nav('../');
  }, [orgChanged]);

  useEffect(() => {
    refetch();
    return () => {
      clearMeeting();
    };
  }, [id]);

  const closeDelete = () => setToDelete(null);
  const onDelete = () =>
    deleteMeeting(id)
      .then(closeDelete)
      .then(() => {
        nav('../');
      });

  return (
    <Wrapper
      header={
        <Header
          meeting={meeting || {}}
          meetingId={id}
          onDelete={() => setToDelete(meeting)}
        />
      }
      hideNav
      childSx={{
        height: 'calc(100vh - var(--header-height))',
        overflowY: 'hidden',
      }}
    >
      <Stack gap={1} sx={{ minHeight: '100%', height: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Text
            variant="body1"
            color="text.secondary"
            sx={{ textDecoration: 'none' }}
            component={Link}
            to="/meetings"
          >
            {t('meetings_title')}
          </Text>
          <Text variant="body1" color="text.primary">
            {id}
          </Text>
        </Breadcrumbs>
        {loading || !meeting ? (
          <Stack justifySelf="center" alignSelf="center">
            <CircularProgress />
            <Text>{t('loading')}</Text>
          </Stack>
        ) : (
          <>
            <VideoAndTranscript meeting={meeting} />

            <DeleteDialog
              open={Boolean(toDelete)}
              onConfirm={onDelete}
              onCancel={closeDelete}
            >
              {t(`confirm_delete {{id}}`, { id: toDelete?.id })}
            </DeleteDialog>
          </>
        )}
      </Stack>
    </Wrapper>
  );
}
