import { t } from 'i18next';
import { saveAs } from 'file-saver';
import { cancelCalls, addCtCheck, get } from '../utils/api';
import analyticsStore from '../stores/AnalyticsStore';
import userStore from '../stores/UserStore';
import { addSnack } from './SnackActions';

async function getAnalytics(type, { fromMs, toMs } = {}) {
  const o = userStore.org;
  if (!o) return Promise.reject();

  const key = `getAnalytics-${type}`;
  cancelCalls(key);
  const cancelToken = addCtCheck(key);

  if (!fromMs) {
    // eslint-disable-next-line no-param-reassign
    fromMs = new Date();
    fromMs.setDate(fromMs.getDate() - 90);
    // eslint-disable-next-line no-param-reassign
    fromMs = fromMs.getTime();
  }
  if (!toMs) {
    // eslint-disable-next-line no-param-reassign
    toMs = new Date().getTime();
  }

  const response = await get(
    `/orgs/${o}/analytics`,
    { params: { type, fromMs, toMs } },
    cancelToken
  );
  return response?.data;
}

export async function getMeetAnalytics({ fromMs, toMs } = {}) {
  return getAnalytics('meet', { fromMs, toMs })
    .then((data) => {
      if (!data) return;
      analyticsStore.meetAnalytics = data;
    })
    .catch(() => {
      addSnack(t('analytics.meet.error'));
    });
}

export async function getEmbeddedAnalytics({ fromMs, toMs } = {}) {
  return getAnalytics('embedded', { fromMs, toMs })
    .then((data) => {
      if (!data) return;
      analyticsStore.embeddedAnalytics = data;
    })
    .catch(() => {
      addSnack(t('analytics.embedded.error'));
    });
}

function dataToCsv(data, sep = ';') {
  if (data.length === 0) return '';
  const headers = Object.keys(data[0]);
  const values = data.map((row) => headers.map((h) => row[h]));
  return [headers, ...values].map((row) => row.join(sep)).join('\n');
}

export async function exportCsv(data) {
  const csv = dataToCsv(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, 'analytics.csv');
}
