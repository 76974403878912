import { useTranslation } from 'react-i18next';
import { Button, Card, Divider, Stack, Text } from '@compodium/ui-lib/material';

const stripeCustomerUrl =
  process.env.REACT_APP_STRIPE_CUSTOMER_URL || window.STRIPE_CUSTOMER_URL;

export default function PriceSummary({ sub, renewalDate, isAdmin }) {
  const { t } = useTranslation();
  const { embedded, meet } = sub || {};
  const hasMeet = !!meet;
  const hasEmbedded = !!embedded;
  const embeddedPrice = (embedded?.product?.price?.unitAmount || 0) / 100;
  const meetPrice = (meet?.product?.price?.unitAmount || 0) / 100;
  const totalCost = embeddedPrice + meetPrice;
  const meetCurrency = meet?.product?.price?.currency?.toUpperCase() || 'SEK';
  const embedCurrency =
    embedded?.product?.price?.currency?.toUpperCase() || 'SEK';

  return (
    <Stack gap={0.5}>
      <Text variant="h5" color="primary.main">
        {t('subscription_details')}
      </Text>
      <Card sx={{ px: 1, py: 1.5 }}>
        <Stack gap={2}>
          <Stack
            gap={1}
            sx={{
              maxWidth: 600,
              width: '100%',
              color: 'text.secondary',
            }}
          >
            <Stack id="items" gap={0.75}>
              {hasEmbedded && (
                <Stack direction="row" justifyContent="space-between">
                  <Text>{embedded?.product?.name}</Text>
                  <Text>{`${embedCurrency} ${embeddedPrice}`}</Text>
                </Stack>
              )}
              {hasMeet && (
                <Stack direction="row" justifyContent="space-between">
                  <Text>{meet?.product?.name}</Text>
                  <Text>{`${meetCurrency} ${meetPrice}`}</Text>
                </Stack>
              )}
            </Stack>
            <Divider />
            <Stack id="total" direction="row" justifyContent="space-between">
              <Text>{t('sub_total')}</Text>
              <Text>{`${meetCurrency} ${totalCost}`}</Text>
            </Stack>
            <Text variant="caption" fontWeight={600}>
              {t('sub_renewal {{renewalDate}}', { renewalDate })}
            </Text>
          </Stack>
          {isAdmin && (
            <Stack direction="row" gap={1}>
              <Button
                variant="contained"
                color="primary"
                href={stripeCustomerUrl}
                target="_blank"
                sx={{ height: 48 }}
              >
                {t('manage_subscription')}
              </Button>
            </Stack>
          )}
        </Stack>
      </Card>
    </Stack>
  );
}
