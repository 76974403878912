import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@compodium/ui-lib/material';
import useIsMobile from '../../hooks/useIsMobile';

export default function Title() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Stack
      gap={1}
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
    >
      <Stack gap={1}>
        <Text variant="h4">{t('subscriptions_title')}</Text>
        <Text variant="body1" color="text.secondary">
          {t('subscriptions_desc')}
        </Text>
      </Stack>
    </Stack>
  );
}
