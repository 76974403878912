import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Stack,
  Tabs,
  Tab,
  Text,
} from '@compodium/ui-lib/material';
import {
  ListMembers,
  Requests,
  WorkspaceOptionsMenu,
} from '../components/Admin';
import TabPanel from '../components/TabPanel';
import Wrapper from '../components/layout/Wrapper';
import useUser from '../hooks/useUser';

function Title() {
  const { t } = useTranslation();
  const { orgDisplayName } = useUser();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack gap={{ xs: 1, sm: 0 }}>
        <Breadcrumbs>
          <Text component={Link} to="/" color="primary">
            {t('home_title')}
          </Text>
          <Text color="primary">{orgDisplayName}</Text>
        </Breadcrumbs>
        <Text variant="h4">
          {t('admin_title {{org}}', { org: orgDisplayName })}
        </Text>
        <Text variant="body1" color="text.secondary">
          {t('admin_title_desc')}
        </Text>
      </Stack>

      <div>
        <WorkspaceOptionsMenu />
      </div>
    </Stack>
  );
}

export default function Admin() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'members';
  const initIdx = { members: 0, requests: 1 };
  const [idx, setIdx] = useState(initIdx[tab] || 0);

  const handle = (_, v) => setIdx(v);

  return (
    <Wrapper>
      <Stack gap={2}>
        <Title />
        <Tabs
          value={idx}
          onChange={handle}
          sx={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'divider',
          }}
        >
          <Tab label={t('admin_tab_members')} />
          <Tab label={t('admin_tab_requests')} />
          {/* <Tab label={t('admin_tab_invites')} /> */}
        </Tabs>
        <TabPanel value={idx} index={0}>
          <ListMembers />
        </TabPanel>
        <TabPanel value={idx} index={1}>
          <Requests />
        </TabPanel>
      </Stack>
    </Wrapper>
  );
}
