import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Divider,
  Stack,
  Text,
} from '@compodium/ui-lib/material';
import { styled } from '@compodium/ui-lib/material/styles';
import Logo from '../Logo';
import UserCard from '../UserCard';
import useIsMobile from '../../hooks/useIsMobile';
import useUser from '../../hooks/useUser';
import styles from './Wrapper.module.css';

const Nav = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  fontSize: 12,
}));

function Header() {
  const isMobile = useIsMobile();
  const { darkmode } = useUser();

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between" gap={1} pt="20px">
        <Stack sx={{ px: { xs: 1, sm: 'inherit' } }}>
          <Logo variant={isMobile ? 'short' : 'long'} darkmode={darkmode} />
        </Stack>
        <UserCard variant="medium" sx={{ px: { xs: 1, sm: 'inherit' } }} />
      </Stack>
      {isMobile && <Divider flexItem />}
    </Stack>
  );
}

function Footer() {
  const { t } = useTranslation();
  return (
    <Stack
      gap={1}
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems="space-between"
      pb={1}
      pl={1}
    >
      <Nav to="https://docs.compodium.com/" target="_blank" rel="noreferrer">
        {t('docs_title')}
      </Nav>
      <Nav
        to="https://docs.compodium.com/docs/Privacy%20and%20Terms/Privacy%20Policy/"
        target="_blank"
        rel="noreferrer"
      >
        {t('tos_title')}
      </Nav>
      <Nav to="https://compodium.com/kontakt/" target="_blank" rel="noreferrer">
        {t('contact_title')}
      </Nav>
    </Stack>
  );
}

export default function Wrapper({ children }) {
  return (
    <Container
      maxWidth="xl"
      sx={{ height: '100%', px: { xs: 0, sm: 2 }, overflowY: 'auto' }}
    >
      <Stack direction="row" justifyContent="space-between" height="100%">
        <Stack
          component="main"
          justifyContent="space-between"
          width={{ xs: '100vw', lg: 424 }}
          height="100vh"
        >
          <Header />
          <Stack px={{ xs: 1, sm: 0 }}>{children}</Stack>
          <Footer />
        </Stack>
        <Stack sx={{ display: { xs: 'none', lg: 'block' }, pl: 1 }}>
          <Box className={styles.splash}>
            <Text sx={{ maxWidth: 364 }}>Your digital safe space</Text>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}
