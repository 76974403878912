import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { Button, Card, Stack, Text, Tooltip } from '@compodium/ui-lib/material';
import { Add as AddIcon } from '@compodium/ui-lib/icons';
import { List, Pagination, Table, TablePagination } from '../list';
import InviteUserDialog from './InviteUserDialog';
import MemberModal from './MemberModal';
import DeleteDialog from '../Modals/DeleteDialog';
import SearchBar from '../SearchBar';
import useMemberList from './useMemberList';
import useIsMobile from '../../hooks/useIsMobile';
import useQuery from '../../hooks/useQuery';
import useUser from '../../hooks/useUser';
import useWorkspace from '../../hooks/useWorkspace';
import {
  getMembers,
  removeUser,
  setUserRole,
  inviteUser,
} from '../../actions/WorkspaceActions';

function InviteUserButton({ onClick, disabled }) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const button = (
    <Button
      disabled={disabled}
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onClick}
    >
      {isMobile ? t('invite') : t('invite_user')}
    </Button>
  );

  if (disabled) {
    return (
      <Tooltip
        title={t('max_users_reached')}
        placement="top"
        disableInteractive
      >
        <span>{button}</span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={t('add_more_users')} placement="top">
      <span>{button}</span>
    </Tooltip>
  );
}

function Title({ openInvite, q, setQ, count }) {
  const { t } = useTranslation();
  const { orgObj, orgDisplayName } = useUser();

  const { meet } = orgObj?.subscriptions || {};
  const hasMeet = !!meet;
  const hasSubscription = meet?.tier !== 'free';

  return (
    <Stack gap={1}>
      <SearchBar
        value={q}
        onChange={(e) => setQ(e.target.value)}
        placeholder={t('search {{workspace}}', { workspace: orgDisplayName })}
        isClearable
        sx={{ display: { xs: 'inherit', sm: 'none' } }}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="h5">{t('users')}</Text>
        <Stack direction="row" gap={1} alignItems="center">
          <Text
            variant="subtitle1"
            color="text.secondary"
            sx={{
              whiteSpace: 'nowrap',
              display: { xs: 'none', sm: 'inherit' },
            }}
          >
            {t('users {{count}}', { count })}
          </Text>
          <SearchBar
            value={q}
            onChange={(e) => setQ(e.target.value)}
            placeholder={t('search {{workspace}}', {
              workspace: orgDisplayName,
            })}
            isClearable
            sx={{ display: { xs: 'none', sm: 'inherit' } }}
          />
          <InviteUserButton
            onClick={openInvite}
            disabled={hasMeet && !hasSubscription}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function ListMembers() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { id: myId } = useUser();
  const { members, totalMembers: total } = useWorkspace();
  const { page, setPage, offset, pageSize, setPageSize } = useQuery({
    pageSize: 50,
    total,
  });
  const [rawQ, setQ] = useState('');
  const [q] = useDebounce(rawQ, 500);
  const [toDelete, setToDelete] = useState(null);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [edit, setEdit] = useState(null);

  const headers = [t('name'), t('email'), t('role'), ''];

  const openInvite = () => setInviteOpen(true);
  const doCloseInvite = () => setInviteOpen(false);

  const refetch = useCallback(
    async () => getMembers({ q, offset, pageSize }),
    [q, offset, pageSize]
  );

  const close = () => {
    setEdit(null);
    setToDelete(null);
  };
  const onDelete = () => {
    close();
    setEdit(null);
    removeUser(toDelete.id).then(refetch);
  };

  const onRoleChange = (id, role) =>
    setUserRole(id, role).then(refetch).then(close);

  const onInvite = (email, role) =>
    inviteUser(email, role).then(doCloseInvite).then(refetch);

  const { renderRow, renderItem } = useMemberList({
    myId,
    onDelete: setToDelete,
    onRoleChange,
    refetch,
    onClick: setEdit,
  });

  useEffect(() => {
    refetch();
  }, [refetch, page, pageSize, q]);

  return (
    <Stack gap={{ xs: 1, sm: 0.5 }}>
      <Title openInvite={openInvite} q={rawQ} setQ={setQ} count={total} />
      {isMobile ? (
        <>
          <List data={members} renderItem={renderItem} />
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            total={total}
          />
        </>
      ) : (
        <Card sx={{ borderRadius: 2 }}>
          <Table
            data={members}
            headers={headers}
            renderRow={renderRow}
            footer={
              <TablePagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={total}
              />
            }
          />
        </Card>
      )}
      <DeleteDialog
        open={Boolean(toDelete)}
        onCancel={close}
        onConfirm={onDelete}
        title={t('confirm_delete_title')}
      >
        {t('confirm_delete {{id}}', { id: `${toDelete?.email}` })}
      </DeleteDialog>
      <InviteUserDialog
        open={inviteOpen}
        onClose={doCloseInvite}
        onSubmit={onInvite}
      />
      <MemberModal
        open={Boolean(edit)}
        onClose={() => setEdit(null)}
        myId={myId}
        member={edit}
        onRoleChange={onRoleChange}
        onDelete={() => setToDelete(edit)}
      />
    </Stack>
  );
}
