import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Text,
} from '@compodium/ui-lib/material';
import DownloadIcon from '@compodium/ui-lib/icons/FileDownload';
import { getRecDl } from '../../actions/MeetingActions';

export default function DlMeeting({
  type = 'button',
  meeting,
  disabled = false,
}) {
  const { t } = useTranslation();
  const { id, playbackExists } = meeting;
  const videoName = `${id}.mp4`;
  const videoDl = getRecDl(id);

  const text = `${t('meeting')}${t(
    playbackExists ? '' : ` (${t('not_ready')})`
  )}`;

  if (type === 'list-item') {
    return (
      <ListItem disableGutters>
        <ListItemButton
          download
          href={videoDl}
          filename={videoName}
          disabled={disabled || !playbackExists}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>{text}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }

  if (type === 'button') {
    return (
      <Button
        download
        href={videoDl}
        filename={videoName}
        startIcon={<DownloadIcon />}
        disabled={disabled || !playbackExists}
      >
        {text}
      </Button>
    );
  }
  if (type === 'text') {
    return (
      <Text
        component={playbackExists ? 'a' : 'span'}
        download
        href={videoDl}
        filename={videoName}
        color="text.primary"
      >
        {text}
      </Text>
    );
  }

  throw new Error(`Unsupported type '${type}'`);
}
