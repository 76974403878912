import { useTranslation } from 'react-i18next';
import { Button, Stack, Text } from '@compodium/ui-lib/material';
import CopyIcon from '@compodium/ui-lib/icons/ContentCopy';
import Dialog from '../Modals/Dialog';
import { addSnack } from '../../actions/SnackActions';

function CopyButton({ title, onClick }) {
  return (
    <Button onClick={onClick} startIcon={<CopyIcon />}>
      {title}
    </Button>
  );
}

export default function ViewIntegrationDialog({ integration, onClose }) {
  const { t } = useTranslation();
  const sx = { textOverflow: 'ellipsis' };
  const { apiKey, secret } = integration || {};

  const copyToClipboard = (text, msg) => {
    navigator.clipboard.writeText(text);
    addSnack(msg);
  };

  const copyApiKey = () => {
    copyToClipboard(apiKey, t('api_key_copied'));
  };
  const copySecret = () => {
    copyToClipboard(secret, t('secret_copied'));
  };

  return (
    <Dialog
      open={Boolean(integration)}
      onClose={onClose}
      hideSubmit
      actions={
        <>
          <CopyButton onClick={copySecret} title={t('secret_title_short')} />
          <CopyButton onClick={copyApiKey} title={t('api_key_title')} />
        </>
      }
      actionsSx={{ flexDirection: 'column-reverse', gap: 0.5 }}
    >
      <Stack gap={1} maxWidth="100%">
        <Text sx={sx}>{`${t('api_key_title')}: ${apiKey}`}</Text>
        {!!secret && <Text sx={sx}>{`${t('secret_title')}: ${secret}`}</Text>}
      </Stack>
    </Dialog>
  );
}
