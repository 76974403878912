import axios from 'axios';
import userStore from '../stores/UserStore';

const CANCEL_TOKENS = {};
const ROOT_URL = '/api';

export const MEET_URL =
  process.env.REACT_APP_MEET_URL ||
  window.COMP_MEET_URL ||
  'http://localhost:3020';

export function addCtCheck(key) {
  const t = axios.CancelToken.source();
  CANCEL_TOKENS[key] = t;
  return t.token;
}

export function ctExists(key) {
  return key in CANCEL_TOKENS;
}

export function cancelCalls(key) {
  const ct = CANCEL_TOKENS?.[key];
  if (!ct) return false;
  ct.cancel(`${key}: anrop avbrutet`);
  delete CANCEL_TOKENS[key];
  return true;
}

// ---------

export function request(params) {
  const url = ROOT_URL + params.url;

  return axios({
    ...params,
    url,
  }).catch((e) => {
    if (!axios.isCancel(e)) {
      if (e?.response?.status === 401) {
        Object.keys(CANCEL_TOKENS).forEach((key) => {
          cancelCalls(key);
        });

        userStore.setIsLoggedIn(false);
      }
      throw e;
    }
  });
}

export function get(url, { skipSnack, ...params } = {}, cancelToken = null) {
  return request(
    {
      url,
      method: 'GET',
      cancelToken,
      ...params,
    },
    skipSnack
  );
}

export function post(url, data, { skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'POST',
      ...params,
    },
    skipSnack
  );
}

export function put(url, data, { skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'PUT',
      ...params,
    },
    skipSnack
  );
}

export function patch(url, data, { skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'PATCH',
      ...params,
    },
    skipSnack
  );
}

export function del(url, data, { skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'DELETE',
      ...params,
    },
    skipSnack
  );
}

export const objectToQuery = (o) =>
  Object.entries(o)
    .filter(([k, v]) => k && v)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
