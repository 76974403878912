function StripeTable({ clientReferenceId, pricingTableId, publishableKey }) {
  if (!(pricingTableId && publishableKey && clientReferenceId)) {
    return <p>Missing variables</p>;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      client-reference-id={clientReferenceId}
    />
  );
}

export function MeetStripeTable({ clientReferenceId }) {
  const pricingTableId =
    process.env.REACT_APP_STRIPE_MEET_PRICING_TABLE_ID ||
    window.STRIPE_MEET_PRICING_TABLE_ID;

  const publishableKey =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    window.STRIPE_PUBLISHABLE_KEY;

  if (!(pricingTableId && publishableKey && clientReferenceId)) {
    return <p>Missing variables</p>;
  }

  return (
    <StripeTable
      pricingTableId={pricingTableId}
      publishableKey={publishableKey}
      clientReferenceId={clientReferenceId}
    />
  );
}

export function EmbeddedStripeTable({ clientReferenceId }) {
  const pricingTableId =
    process.env.REACT_APP_STRIPE_EMBEDDED_PRICING_TABLE_ID ||
    window.STRIPE_EMBEDDED_PRICING_TABLE_ID;

  const publishableKey =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    window.STRIPE_PUBLISHABLE_KEY;

  if (!(pricingTableId && publishableKey && clientReferenceId)) {
    return <p>Missing variables</p>;
  }

  return (
    <StripeTable
      pricingTableId={pricingTableId}
      publishableKey={publishableKey}
      clientReferenceId={clientReferenceId}
    />
  );
}
