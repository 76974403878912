import { TextField } from '@compodium/ui-lib/material';

export default function DateTimePicker(props) {
  const { label, value, onChange } = props;

  return (
    <TextField
      label={label}
      type="datetime-local"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
