import { useNavigate } from 'react-router-dom';
import Box from '@compodium/ui-lib/material/Box';

export default function Logo({
  darkmode = false,
  variant = 'long',
  height = '24px',
  sx = {},
}) {
  const nav = useNavigate();
  if (!['long', 'short'].includes(variant)) {
    throw new Error('Invalid logo variant');
  }
  const PUBLIC_URL = process.env.PUBLIC_URL || '';
  const shade = darkmode ? 'white' : 'dark';
  const src = `${PUBLIC_URL}/svgs/${variant}_${shade}.svg`;
  return (
    <Box
      component="img"
      src={src}
      alt="logo"
      loading="lazy"
      onClick={() => nav('/')}
      sx={{ height, cursor: 'pointer', ...sx }}
    />
  );
}
