import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUser from '../hooks/useUser';
import { getOrgs } from '../actions/UserActions';
import { addSnack } from '../actions/SnackActions';
import Loading from './Loading';

export default function SetOrganisationWrapper({
  ChildComponent,
  requireOrgs = false,
  fallback = '/',
  msg,
  ...childProps
}) {
  const { orgsPopulated, orgs, loading } = useUser();
  const [hasOrgs, setHasOrgs] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrgs = () => {
      getOrgs();
    };
    if (!loading && !orgsPopulated) fetchOrgs();
    if (orgsPopulated) {
      const gotOrgs = orgs.length > 0;
      setHasOrgs(gotOrgs);
      if (requireOrgs && !gotOrgs) {
        addSnack(msg || t('forbidden_route_unless_workspace'));
        navigate(fallback);
      }
    }
  }, [orgsPopulated, loading]);

  if (!orgsPopulated) return <Loading />;
  if (requireOrgs && !hasOrgs) return <Loading />;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ChildComponent {...childProps} />;
}
