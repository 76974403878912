import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListItemIcon, MenuItem } from '@compodium/ui-lib/material';
import DeleteIcon from '@compodium/ui-lib/icons/Delete';
import DeleteDialog from '../Modals/DeleteDialog';
import useUser from '../../hooks/useUser';
import { deleteWorkspace } from '../../actions/WorkspaceActions';
import { addSnack } from '../../actions/SnackActions';
import { clearOrg, getOrgs } from '../../actions/UserActions';

export default function DeleteWorkspace() {
  const { t } = useTranslation();
  const { org } = useUser();
  const nav = useNavigate();
  const errorRef = useRef();
  const [open, setOpen] = useState(false);

  useEffect(
    () => () => {
      if (errorRef.current) {
        clearTimeout(errorRef.current);
      }
    },
    []
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onConfirm = () =>
    deleteWorkspace()
      .catch((e) => {
        if (e?.response?.status === 412) {
          addSnack(
            t('delete_workspace_error {{reason}}', {
              reason: t('precondition_failed'),
            })
          );
        }
      })
      .then(handleClose)
      .then(clearOrg)
      .then(getOrgs)
      .then(() => nav('/'));

  return (
    <>
      <MenuItem variant="contained" color="error" onClick={handleOpen}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        {t('delete_workspace')}
      </MenuItem>
      <DeleteDialog
        open={open}
        title={t('delete_workspace_title {{name}}', { name: org })}
        onConfirm={onConfirm}
        onCancel={handleClose}
      >
        {t('delete_workspace_desc')}
      </DeleteDialog>
    </>
  );
}
