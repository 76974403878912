import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@compodium/ui-lib/material';
import { MeetStripeTable } from '../StripeTable';

export default function MeetPricingTable({ clientReferenceId }) {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Stack gap={0.5}>
        <Text variant="h5" color="primary.main">
          {t('subscriptions_meet_title')}
        </Text>
        <Text variant="subtitle2" color="text.secondary">
          {t('subscriptions_meet_desc')}
        </Text>
      </Stack>
      <MeetStripeTable clientReferenceId={clientReferenceId} />
    </Stack>
  );
}
