import { useEffect, useState } from 'react';

export default function useStore(store) {
  const init = store.value;
  const [state, setState] = useState(init);

  useEffect(() => {
    const sub = store.data$.subscribe((data) => setState(data));
    return () => sub.unsubscribe();
  }, []);

  return state;
}
