import { DateTime, Duration } from 'luxon';

const emptyObj = {
  weeks: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const dur = (start, stop) =>
  Boolean(start && stop) &&
  Duration.fromObject({
    ...emptyObj,
    seconds: Math.round((stop - start) / 1000),
  })
    .normalize()
    .rescale()
    .toHuman('short');

const toHuman = (millis, format = 'HH:mm:ss, DD') =>
  DateTime.fromMillis(millis || 0).toFormat(format);

const toHumanTime = (millis, format = 'HH:mm:ss') =>
  DateTime.fromMillis(millis || 0).toFormat(format);

const toHumanDate = (millis, format = 'DD') =>
  DateTime.fromMillis(millis || 0).toFormat(format);

export { dur, toHuman, toHumanTime, toHumanDate };
