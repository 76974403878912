import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Text } from '@compodium/ui-lib/material';
import DeleteIcon from '@compodium/ui-lib/icons/Delete';
import Dialog from '../Modals/Dialog';
import { dur, toHuman } from '../../utils/time';

function Section({ title, text }) {
  return (
    <Stack>
      <Text variant="overline">{title}</Text>
      <Text variant="body1">{text}</Text>
    </Stack>
  );
}

export default function ViewMeetingDialog({
  open,
  onClose,
  meeting,
  onDelete,
}) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { id, createdAt, stoppedAt, playback, transcribe } = meeting || {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={id}
      onSubmit={() => nav(id)}
      submitText={t('show_meeting')}
    >
      <Stack gap={2}>
        <Stack gap={0.5}>
          <Section title={t('started')} text={toHuman(createdAt)} />
          <Section title={t('duration')} text={dur(createdAt, stoppedAt)} />
          <Section
            title={t('meeting_recorded')}
            text={t(playback ? 'yes' : 'no')}
          />
          <Section
            title={t('meeting_transcribed')}
            text={t(transcribe ? 'yes' : 'no')}
          />
        </Stack>

        <Button
          startIcon={<DeleteIcon />}
          onClick={onDelete}
          variant="outlined"
          color="error"
        >
          {t('delete_meeting')}
        </Button>
      </Stack>
    </Dialog>
  );
}
