/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@compodium/ui-lib/material';

export default function Dialog({
  open,
  onClose,
  title,
  children,
  onSubmit,
  actions,
  cancelText,
  hideCancel,
  submitText,
  submitIcon,
  hideSubmit,
  submitColor = 'primary',
  actionsSx = {},
  ...props
}) {
  const { t } = useTranslation();

  return (
    <MuiDialog open={open} onClose={onClose} maxWidth="sm" fullWidth {...props}>
      {Boolean(title) && <DialogTitle>{title}</DialogTitle>}
      {Boolean(children) && <DialogContent>{children}</DialogContent>}
      <DialogActions sx={actionsSx}>
        {!hideCancel && (
          <Button variant="outlined" onClick={onClose}>
            {cancelText || t('cancel')}
          </Button>
        )}
        {!hideSubmit && (
          <Button
            variant="contained"
            onClick={onSubmit}
            color={submitColor}
            startIcon={submitIcon}
          >
            {submitText || t('save')}
          </Button>
        )}
        {actions}
      </DialogActions>
    </MuiDialog>
  );
}
