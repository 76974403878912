import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from '../hooks/useUser';
import { getMe, getOrgs, setOrg } from '../actions/UserActions';
import Loading from '../components/Loading';

function PickWorkspace() {
  const { orgs, orgsPopulated, currentGroupId } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!orgsPopulated) {
      getMe().then(() => {
        getOrgs();
      });
    }
  }, [orgsPopulated]);

  if (!orgsPopulated) {
    return <Loading />;
  }

  // Workspace picking logic.
  if (orgs.length === 1) {
    // If only one workspace, set and navigate to it
    setOrg(orgs[0].name).then(() => {
      navigate('/');
    });
    return null;
  }
  if (orgs.length > 1 && currentGroupId) {
    // if multiple and currentGroupId is defined, match and navigate to it
    const { name } = orgs.find((x) => x.id === currentGroupId) || {};
    if (name) {
      setOrg(name).then(() => {
        navigate('/');
      });
    }
    return null;
  }

  // If no workspace could be picked leave it to the user
  navigate('/workspaces');
  return null;
}

export default PickWorkspace;
