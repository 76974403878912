import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@compodium/ui-lib/material';
import PasswordField from '../PasswordField';
import { changePassword } from '../../actions/UserActions';

const PASS_LENGTH = 3;

export default function PasswordForm() {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handle = (key) => (e) => {
    switch (key) {
      case 'oldPassword':
        setOldPassword(e.target.value);
        break;
      case 'newPassword':
        setNewPassword(e.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const disabled = !newPassword.length || newPassword !== confirmPassword;
  const passError = !!newPassword.length && newPassword.length < PASS_LENGTH;
  const confirmError =
    !!newPassword.length &&
    confirmPassword.length &&
    newPassword !== confirmPassword;
  const passHelper = passError
    ? t('pass_error {{length}}', { length: PASS_LENGTH })
    : null;
  const confirmHelper = confirmError ? t('passwords_mismatch') : null;

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || passError || confirmError) {
      return;
    }

    changePassword({ oldPassword, newPassword });
  };

  return (
    <Stack
      gap={1}
      component="form"
      onSubmit={onSubmit}
      width="100%"
      maxWidth={400}
    >
      <PasswordField
        label={t('old_password_title')}
        value={oldPassword}
        onChange={handle('oldPassword')}
      />
      <PasswordField
        label={t('new_password_title')}
        value={newPassword}
        onChange={handle('newPassword')}
        error={passError}
        helperText={passHelper}
      />
      <PasswordField
        label={t('confirm_password_title')}
        value={confirmPassword}
        onChange={handle('confirmPassword')}
        error={confirmError}
        helperText={confirmHelper}
      />
      <Button disabled={disabled} variant="contained" type="submit">
        {t('change_password')}
      </Button>
    </Stack>
  );
}
