import { useTranslation } from 'react-i18next';
import BannerWithIcon from '../BannerWithIcon';
import { Cam } from '../icons';
import useUser from '../../hooks/useUser';

export default function MeetFreeBanner() {
  const { t } = useTranslation();
  const { darkmode } = useUser();
  return (
    <BannerWithIcon
      icon={<Cam gray darkmode={darkmode} />}
      title={t('meet_free_banner_title')}
      description={t('meet_free_banner_desc')}
    />
  );
}
