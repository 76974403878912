import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Text } from '@compodium/ui-lib/material';
import Dialog from '../Modals/Dialog';
import useUser from '../../hooks/useUser';
import { getOrgs } from '../../actions/UserActions';
import { editWorkspace } from '../../actions/WorkspaceActions';

export default function EditWorkspaceDialog({ open, onClose }) {
  const { t } = useTranslation();
  const { orgDisplayName } = useUser();
  const [name, setName] = useState(orgDisplayName);
  const [error, setError] = useState('');

  const handle = (key) => (e) => {
    switch (key) {
      case 'name':
        setName(e.target.value);
        break;
      default:
        break;
    }
  };

  const onSubmit = async () => {
    try {
      await editWorkspace({ name });
      await getOrgs();
      onClose();
    } catch (e) {
      const err = e?.response?.data?.detail || e?.message || 'Unknown error';
      setError(err);
    }
  };

  const submitOnEnter = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('edit_workspace')}
      onSubmit={onSubmit}
    >
      <Stack gap={1} pt={1}>
        {error ? <Text color="error">error</Text> : null}
        <TextField
          label={t('name')}
          value={name}
          onChange={handle('name')}
          onKeyPress={submitOnEnter}
        />
      </Stack>
    </Dialog>
  );
}
