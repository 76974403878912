import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@compodium/ui-lib/material/Stack';
import Dialog from './Dialog';
import DateTimePicker from '../DateTimePicker';

export default function DateRangeDialog({ open, onClose, from, to, onSubmit }) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);

  const handleSubmit = () => onSubmit(startDate, endDate);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Date Range"
      onSubmit={handleSubmit}
      submitText={t('submit')}
    >
      <Stack pt={1} gap={1} alignItems="center">
        <DateTimePicker
          value={from}
          onChange={(e) => setStartDate(e.target.value)}
          label={t('from')}
        />
        <DateTimePicker
          value={to}
          onChange={(e) => setEndDate(e.target.value)}
          label={t('to')}
        />
      </Stack>
    </Dialog>
  );
}
